import { EQuestionIDS } from './v2/ClicksQuiz/types';
import { SubTitleB } from './v2/ErrorBoundary/styled';
import { ECaseStudyQuestionTypes } from './v2/types';

// ErrorBoundary
export const ERROR_BOUNDARY_MSG = 'ERROR_BOUNDARY_MSG';

// ForgotPassword & ForgotPasswordSubmit
export const RECOVER_YOUR_PASSWORD_HEADING = 'RECOVER_YOUR_PASSWORD_HEADING';
export const CHANGE_YOUR_PASSWORD_HEADING = 'CHANGE_YOUR_PASSWORD_HEADING';
export const FORGOT_PASSWORD_GUIDE_TEXT = 'FORGOT_PASSWORD_GUIDE_TEXT';
export const FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT = 'FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT';
export const FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2 = 'FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2';
export const USERNAME_OR_EMAIL_FIELD_LABEL = 'USERNAME_OR_EMAIL_FIELD_LABEL';
export const CONFIRMATION_CODE_FILED_LABEL = 'CONFIRMATION_CODE_FILED_LABEL';
export const SEND_RECOVERY_EMAIL_BUTTON_LABEL = 'SEND_RECOVERY_EMAIL_BUTTON_LABEL';
export const CHANGE_PASSWORD_BUTTON_LABEL = 'CHANGE_PASSWORD_BUTTON_LABEL';
export const INVALID_CODE_ERROR_MSG = 'INVALID_CODE_ERROR_MSG';

// NewPasswordRequired
export const NEW_PASSWORD_REQUIRED_HEADING = 'NEW_PASSWORD_REQUIRED_HEADING';
export const UNKOWN_EMAIL_PLACEHOLDER_TEXT = 'UNKOWN_EMAIL_PLACEHOLDER_TEXT';
export const RE_ENTER_NEW_PASSWORD_FIELD_LABEL = 'RE_ENTER_NEW_PASSWORD_FIELD_LABEL';
export const CREATE_PASSWORD_BUTTON_LABEL = 'CREATE_PASSWORD_BUTTON_LABEL';

// QuizMeplanSubTitleTrial
export const QUIZ_ME_TITLE = 'QUIZ_ME_TITLE';

// routing
export const AUTH_BASE_ROUTE_TEXT = 'AUTH_BASE_ROUTE_TEXT';
export const MYCONTENT_BASE_ROUTE_TEXT = 'MYCONTENT_BASE_ROUTE_TEXT';
export const LOGIN_ROUTE_TEXT = 'LOGIN_ROUTE_TEXT';
export const NEW_PASSWORD_REQUIRED_ROUTE_TEXT = 'NEW_PASSWORD_REQUIRED_ROUTE_TEXT';
export const FORGOT_PASSWORD_ROUTE_TEXT = 'FORGOT_PASSWORD_ROUTE_TEXT';
export const FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT = 'FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT';
export const REGISTER_ROUTE_TEXT = 'REGISTER_ROUTE_TEXT';
export const REGISTER_CONFIRMATION_ROUTE_TEXT = 'REGISTER_CONFIRMATION_ROUTE_TEXT';
export const SELECTED_VIDEO_ID_ROUTE_TEXT = 'SELECTED_VIDEO_ID_ROUTE_TEXT';
export const VIDEO_ROUTE_TEXT = 'VIDEO_ROUTE_TEXT';
export const UPLOADS_ROUTE_TEXT = 'UPLOADS_ROUTE_TEXT';
export const UPLOADING_VIDEO_ID = 'UPLOADING_VIDEO_ID';

// Login
export const REGISTER_BUTTON_LABEL = 'REGISTER_BUTTON_LABEL';
export const FORGOT_PASSWORD_QUESTION_MARK_TEXT = 'FORGOT_PASSWORD_QUESTION_MARK_TEXT';
export const LOGIN_BUTTON_LABEL = 'LOGIN_BUTTON_LABEL';
export const NOT_REGISTERED_QUESTION_MARK_TEXT = 'NOT_REGISTERED_QUESTION_MARK_TEXT';
export const NO_PROBLEM_EXCLAMATION_MARK_TEXT = 'NO_PROBLEM_EXCLAMATION_MARK_TEXT';
export const LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT = 'LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT';
export const PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG = 'PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG';

// Register & RegisterConfirmation
export const REGISTER_HEADING = 'REGISTER_HEADING';
export const MISSING_FULL_NAME_ERROR_MSG = 'MISSING_FULL_NAME_ERROR_MSG';
export const FULL_NAME_FIELD_LABEL = 'FULL_NAME_FIELD_LABEL';
export const FULL_NAME_EXAMPLE_TEXT = 'FULL_NAME_EXAMPLE_TEXT';
export const RE_ENTER_PASSWORD_FIELD_LABEL = 'RE_ENTER_PASSWORD_FIELD_LABEL';
export const CREATE_ACCOUNT_BUTTON_LABEL = 'CREATE_ACCOUNT_BUTTON_LABEL';
export const REGISTER_CONFIRMATION_GUIDE_TEXT = 'REGISTER_CONFIRMATION_GUIDE_TEXT';
export const REGISTER_CONFIRMATION_GUIDE_TEXT_2 = 'REGISTER_CONFIRMATION_GUIDE_TEXT_2';
export const REGISTER_CONFIRMATION_GUIDE_TEXT_3 = 'REGISTER_CONFIRMATION_GUIDE_TEXT_3';
export const INVALID_CODE_LENGTH_ERROR_MSG = 'INVALID_CODE_LENGTH_ERROR_MSG';
export const USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG = 'USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG';
export const REGISTER_CONFIRMATION_USERNAME_TEXT = 'REGISTER_CONFIRMATION_USERNAME_TEXT';

// Transcrript
export const TRANSCRIPT_TITLE = 'TRANSCRIPT_TITLE';
export const SEARCH_TRANSCRIPT_FIELD_LABEL = 'SEARCH_TRANSCRIPT_FIELD_LABEL';
export const TRANSCRIPT_VIDEO_UPLOADING_STATE_TEXT = 'TRANSCRIPT_VIDEO_UPLOADING_STATE_TEXT';
export const TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT =
  'TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT';
export const TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED =
  'TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED';
export const REFRESH_BUTTON_LABEL = 'REFRESH_BUTTON_LABEL';

// VideoThumbnail
export const UPLOADING_LABEL_TEXT = 'UPLOADING_LABEL_TEXT';
export const PROCESSING_LABEL_TEXT = 'PROCESSING_LABEL_TEXT';

// VideoUploadButton
export const UPLOAD_MODAL_TITLE = 'UPLOAD_MODAL_TITLE';
export const MISSING_NAME_ERROR_MSG = 'MISSING_NAME_ERROR_MSG';
export const NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG = 'NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG';
export const FAILED_TO_CAPTURE_FILE_ERROR_MSG = 'FAILED_TO_CAPTURE_FILE_ERROR_MSG';
export const INVALID_FILE_ERROR_MSG = 'INVALID_FILE_ERROR_MSG';
export const INVALID_FILE_NAME_ERROR_MSG = 'INVALID_FILE_NAME';
export const INVALID_FILE_EXTENSION_ERROR_MSG = 'INVALID_FILE_EXTENSION_ERROR_MSG';
export const FILE_SIZE_OVER_LIMIT_ERROR_MSG = 'FILE_SIZE_OVER_LIMIT_ERROR_MSG';
export const NAME_FIELD_LABEL = 'NAME_FIELD_LABEL';
export const FILE_NAME_FIELD_LABEL = 'FILE_NAME_FIELD_LABEL';
export const UPLOAD_BUTTON_LABEL = 'UPLOAD_BUTTON_LABEL';
export const PAGINATION_OF_TEXT = 'PAGINATION_OF_TEXT';
export const PAGINATION_PAGE_TEXT = 'PAGINATION_PAGE_TEXT';
export const SHARE_YOUR_RESOURCE_SUBHEADING = 'SHARE_YOUR_RESOURCE_SUBHEADING';
export const SHARE_WITH_LABEL = 'SHARE_WITH_LABEL';
export const EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL = 'EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL';
export const UPLOAD_FILE_FIELD_LABEL = 'UPLOAD_FILE_FIELD_LABEL';

// VideoMenu
export const PLAYBACK_SPEED_MENU_ITEM = 'PLAYBACK_SPEED_MENU_ITEM';
export const NORMAL_PLAYBACK_SPEED_MENU_ITEM = 'NORMAL_PLAYBACK_SPEED_MENU_ITEM';

// OriginalHeader
export const MISSING_FIRST_NAME_ERROR_MSG = 'MISSING_FIRST_NAME_ERROR_MSG';
export const MISSING_LAST_NAME_ERROR_MSG = 'MISSING_LAST_NAME_ERROR_MSG';
export const MISSING_EMAIL_ERROR_MSG = 'MISSING_EMAIL_ERROR_MSG';
export const INVITE_A_FRIEND_SUCCESS_MSG = 'INVITE_A_FRIEND_SUCCESS_MSG';
export const INVITE_A_FRIEND_MENU_ITEM = 'INVITE_A_FRIEND_MENU_ITEM';
export const INVITE_A_FRIEND_MODAL_TITLE = 'INVITE_A_FRIEND_MODAL_TITLE';
export const INVITE_A_FRIEND_MODAL_GUIDE_TEXT = 'INVITE_A_FRIEND_MODAL_GUIDE_TEXT';
export const FIRST_NAME_FIELD_LABEL = 'FIRST_NAME_FIELD_LABEL';
export const LAST_NAME_FIELD_LABEL = 'LAST_NAME_FIELD_LABEL';
export const SEND_BUTTON_EMAIL = 'SEND_BUTTON_EMAIL';
export const SELECT_THEME_TITLE = 'SELECT_THEME_TITLE';
export const CHANGE_THEME_MENU_ITEM = 'CHANGE_THEME_MENU_ITEM';
export const LOGOUT_MENU_ITEM = 'LOGOUT_MENU_ITEM';
export const OPEN_LEFT_PANE_ICON_TEXT = 'OPEN_LEFT_PANE_ICON_TEXT';
export const CLOSE_LEFT_PANE_ICON_TEXT = 'CLOSE_LEFT_PANE_ICON_TEXT';

// MyContent
export const VIDEOS_TITLE = 'VIDEOS_TITLE';
export const MY_UPLOADS_TITLE = 'MY_UPLOADS_TITLE';
export const KEYWORDS_TITLE = 'KEYWORDS_TITLE';
export const VIDEO_TITLE_DEFUALT_TEXT = 'Video Title';
export const VIDEO_UPLOADING_PERCENTAGE_PHRASE = 'VIDEO_UPLOADING_PERCENTAGE_PHRASE';
export const KEYWORDS_VIDEO_UPLOADING_STATE_TEXT = 'KEYWORDS_VIDEO_UPLOADING_STATE_TEXT';
export const KEYWORDS_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT =
  'KEYWORDS_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT';

// MyUploads
export const PREVIEW_COLUMN_LABEL = 'PREVIEW_COLUMN_LABEL';
export const SHARED_WITH_COLUMN_LABEL = 'SHARED_WITH_COLUMN_LABEL';
export const RESOURCE_COLUMN_LABEL = 'RESOURCE_COLUMN_LABEL';
export const VIDEO_DELETE_SUCCESS_MSG = 'VIDEO_DELETE_SUCCESS_MSG';
export const VIDEO_DELETE_FAILURE_MSG = 'VIDEO_DELETE_FAILURE_MSG';
export const VIDEO_DELETE_TITLE_TEXT = 'VIDEO_DELETE_TITLE_TEXT';
export const VIDEO_DELETE_CONFIRMATION_MSG = 'VIDEO_DELETE_CONFIRMATION_MSG';
export const VIDEO_DELETE_TABLE_ARIA_LABEL = 'VIDEO_DELETE_TABLE_ARIA_LABEL';

// OrbotAvatar
export const ORBOT_AVATAR_ALT = 'ORBOT_AVATAR_ALT';

// Common
export const CANCEL_BUTTON_LABEL = 'CANCEL_BUTTON_LABEL';
export const OK_BUTTON_LABEL = 'OK_BUTTON_LABEL';
export const DELETE_BUTTON_LABEL = 'DELETE_BUTTON_LABEL';
export const STEP_1_OF_2_LABEL = 'STEP_1_OF_2';
export const STEP_2_OF_2_LABEL = 'STEP_2_OF_2';
export const DEFAULT_ERROR_MSG = 'DEFAULT_ERROR_MSG';
export const MISSING_USERNAME_ERROR_MSG = 'MISSING_USERNAME_ERROR_MSG';
export const INVALID_USERNAME_WITH_SPACE_ERROR_MSG = 'INVALID_USERNAME_WITH_SPACE_ERROR_MSG';
export const MISSING_PASSWORD_ERROR_MSG = 'MISSING_PASSWORD_ERROR_MSG';
export const USERNAME_FIELD_LABEL = 'USERNAME_FIELD_LABEL';
export const PASSWORD_FIELD_LABEL = 'PASSWORD_FIELD_LABEL';
export const EMAIL_FIELD_LABEL = 'EMAIL_FIELD_LABEL';
export const NEW_PASSWORD_FILED_LABEL = 'NEW_PASSWORD_FILED_LABEL';
export const NEW_PASSWORD_FILED_LABEL_2 = 'NEW_PASSWORD_FILED_LABEL_2';
export const PASSWORDS_DONT_MATCH_ERROR_MSG = 'PASSWORDS_DONT_MATCH_ERROR_MSG';
export const MISSING_CODE_ERROR_MSG = 'MISSING_CODE_ERROR_MSG';

// utils
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER = 'PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER';
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER =
  'PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER';
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER =
  'PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER';
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER = 'PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER';
export const INVALID_EMAIL_ERROR_MSG = 'INVALID_EMAIL_ERROR_MSG';
export const USERNAME_TAKEN_ERROR_MSG = 'USERNAME_TAKEN_ERROR_MSG';
export const INVALID_USERNAME_OR_PASSWORD_ERROR_MSG = 'INVALID_USERNAME_OR_PASSWORD_ERROR_MSG';
export const EXPIRED_CODE_OTP_ERROR_MSG = 'EXPIRED_CODE_ERROR_MSG';
export const EXPIRED_CODE_ERROR_MSG_1 = 'EXPIRED_CODE_ERROR_MSG_1';
export const EXPIRED_CODE_ERROR_MSG_2 = 'EXPIRED_CODE_ERROR_MSG_2';
export const EXPIRED_CODE_ERROR_MSG_3 = 'EXPIRED_CODE_ERROR_MSG_3';
export const EXPIRED_CODE_ERROR_MSG_4 = 'EXPIRED_CODE_ERROR_MSG_4';
export const EXPIRED_CODE_ERROR_MSG_5 = 'EXPIRED_CODE_ERROR_MSG_5';
export const CODE_MISMATCH_EXCEPTION_1 = 'CODE_MISMATCH_EXCEPTION_1';
export const CODE_MISMATCH_EXCEPTION_2 = 'CODE_MISMATCH_EXCEPTION_2';
export const CODE_MISMATCH_EXCEPTION_3 = 'CODE_MISMATCH_EXCEPTION_3';
export const NETWORK_ERROR_MSG = 'NETWORK_ERROR_MSG';

export const en = {
  //ErrorBoundary
  [ERROR_BOUNDARY_MSG]: 'Something went wrong 💥.',

  // ForgotPassword & ForgotPasswordSubmit
  [RECOVER_YOUR_PASSWORD_HEADING]: 'Recover your password',
  [CHANGE_YOUR_PASSWORD_HEADING]: 'Change your password',
  [FORGOT_PASSWORD_GUIDE_TEXT]:
    'Enter in your username or email address. An email will be sent to the associated email address with a confirmation code to verify your account.',
  [FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT]:
    "We have sent an smart’n confirmation code to the account's email address, if we have one stored. Please check your inbox as well as your spam folder. Contact help@smartn.ca if you don't receive an email.",
  [FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2]: 'You will be redirected to the login page after changing your password.',
  [USERNAME_OR_EMAIL_FIELD_LABEL]: 'Username or email address (Required)',
  [CONFIRMATION_CODE_FILED_LABEL]: 'Confirmation code (Required)',
  [SEND_RECOVERY_EMAIL_BUTTON_LABEL]: 'Send recovery email',
  [CHANGE_PASSWORD_BUTTON_LABEL]: 'Change password',
  [INVALID_CODE_ERROR_MSG]: 'Invalid code - Valid code consist of 6 digits',

  // NewPasswordRequired
  [NEW_PASSWORD_REQUIRED_HEADING]: 'Email address / username associated with new password',
  [UNKOWN_EMAIL_PLACEHOLDER_TEXT]: 'Unkown email',
  [RE_ENTER_NEW_PASSWORD_FIELD_LABEL]: 'Re-enter new password (Required)',
  [CREATE_PASSWORD_BUTTON_LABEL]: 'Create password',

  // QuizMe
  [QUIZ_ME_TITLE]: 'Quiz Me',

  // routing
  [AUTH_BASE_ROUTE_TEXT]: 'auth',
  [MYCONTENT_BASE_ROUTE_TEXT]: 'mycontent',
  [LOGIN_ROUTE_TEXT]: 'login',
  [NEW_PASSWORD_REQUIRED_ROUTE_TEXT]: 'new-password-required',
  [FORGOT_PASSWORD_ROUTE_TEXT]: 'forgot-password',
  [FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT]: 'forgot-password-submit',
  [REGISTER_ROUTE_TEXT]: 'register',
  [REGISTER_CONFIRMATION_ROUTE_TEXT]: 'register-confrimation',
  [SELECTED_VIDEO_ID_ROUTE_TEXT]: 'selectedResourceId',
  [VIDEO_ROUTE_TEXT]: 'videos',
  [UPLOADS_ROUTE_TEXT]: 'uploads',
  [UPLOADING_VIDEO_ID]: 'uploading',

  // Login
  [REGISTER_BUTTON_LABEL]: 'Register',
  [FORGOT_PASSWORD_QUESTION_MARK_TEXT]: 'Forgot password?',
  [LOGIN_BUTTON_LABEL]: 'Log in',
  [NOT_REGISTERED_QUESTION_MARK_TEXT]: 'Not registered? ',
  [NO_PROBLEM_EXCLAMATION_MARK_TEXT]: ' No problem!',
  [LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT]:
    'If you are interested in smart transcriptions and search tools for your video lessons you can register for smart’n.',
  [PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG]: 'Password should be at least 8 characters',

  // Register & RegisterConfirmation
  [REGISTER_HEADING]: 'Register for smart’n',
  [MISSING_FULL_NAME_ERROR_MSG]: 'Missing Full name',
  [FULL_NAME_FIELD_LABEL]: 'Full name (Required)',
  [FULL_NAME_EXAMPLE_TEXT]: 'An example of a full name is William Shakespeare.',
  [RE_ENTER_PASSWORD_FIELD_LABEL]: 'Re-enter password (Required)',
  [CREATE_ACCOUNT_BUTTON_LABEL]: 'Create account',
  [REGISTER_CONFIRMATION_GUIDE_TEXT]:
    "Thank you for registering with us! We have sent you a confirmation code to verify your smart’n account to your email address. Check your spam folder if you can't locate it.",
  [REGISTER_CONFIRMATION_GUIDE_TEXT_2]:
    'Simply enter in the confirmation code from that email message, and select “Confirm account”.',
  [REGISTER_CONFIRMATION_GUIDE_TEXT_3]: 'You will be redirected to the login page after registering your account.',
  [INVALID_CODE_LENGTH_ERROR_MSG]: (codeLength: number) =>
    `Code consist of 6 chars, currently ${codeLength} chars in input`,
  [USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG]: "The username can't be an email address",
  [REGISTER_CONFIRMATION_USERNAME_TEXT]: 'Username',

  // Transcrript
  [TRANSCRIPT_TITLE]: 'Transcript',
  [SEARCH_TRANSCRIPT_FIELD_LABEL]: 'Search Transcript',
  [TRANSCRIPT_VIDEO_UPLOADING_STATE_TEXT]:
    'The transcript for this video will begin processing when the upload is complete.',
  [TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT]:
    'Your transcription is in process. Check back in a couple of minutes and press',
  [TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED]: ' Refresh!',
  [REFRESH_BUTTON_LABEL]: 'Refresh',

  [UPLOADING_LABEL_TEXT]: 'Uploading',
  [PROCESSING_LABEL_TEXT]: 'Processing',

  // VideoUploadButton
  [UPLOAD_MODAL_TITLE]: 'Upload a resource',
  [MISSING_NAME_ERROR_MSG]: 'Missing name',
  [NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG]: 'Name should be at least 3 characters',
  [FAILED_TO_CAPTURE_FILE_ERROR_MSG]: 'Failed to capture file',
  [INVALID_FILE_ERROR_MSG]: 'Invalid file',
  [INVALID_FILE_NAME_ERROR_MSG]: 'Invalid file name',
  [INVALID_FILE_EXTENSION_ERROR_MSG]: (fileExtension: string, validFileExtensions: string) =>
    `Invalid file extension: ${fileExtension}. You may upload one of ${validFileExtensions} file format.`,
  [FILE_SIZE_OVER_LIMIT_ERROR_MSG]: (overLimitInGB: number) =>
    `File size must be less than 2 GB, your file size is currently ${overLimitInGB} GB over the limit.`,
  [NAME_FIELD_LABEL]: 'Name this content (Required)',
  [FILE_NAME_FIELD_LABEL]: 'Drag and drop file here or click to upload from your computer.',
  [UPLOAD_BUTTON_LABEL]: 'Upload',
  [PAGINATION_OF_TEXT]: 'of',
  [PAGINATION_PAGE_TEXT]: 'Page',
  [SHARE_YOUR_RESOURCE_SUBHEADING]: 'Where should this Content go?',
  [SHARE_WITH_LABEL]: 'Share with',
  [EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL]: 'Select a group to share your video with others.',
  [UPLOAD_FILE_FIELD_LABEL]: 'Upload file (Required)',

  // VideoMenu
  [PLAYBACK_SPEED_MENU_ITEM]: 'Playback Speed',
  [NORMAL_PLAYBACK_SPEED_MENU_ITEM]: 'Normal',

  // OriginalHeader
  [MISSING_FIRST_NAME_ERROR_MSG]: 'Missing first name',
  [MISSING_LAST_NAME_ERROR_MSG]: 'Missing last name',
  [MISSING_EMAIL_ERROR_MSG]: 'Missing email',
  [INVITE_A_FRIEND_SUCCESS_MSG]: (email: string) => `Successfully sent an invitaition to ${email}.`,
  [INVITE_A_FRIEND_MENU_ITEM]: 'Invite a Friend',
  [INVITE_A_FRIEND_MODAL_TITLE]: 'Invite someone you know!',
  [INVITE_A_FRIEND_MODAL_GUIDE_TEXT]:
    'Fill in a bit of information and press "Send" to request an invite for your collaborators!',
  [FIRST_NAME_FIELD_LABEL]: 'First Name (Required)',
  [LAST_NAME_FIELD_LABEL]: 'Last Name (Required)',
  [SEND_BUTTON_EMAIL]: 'Send',
  [SELECT_THEME_TITLE]: 'Select a theme',
  [CHANGE_THEME_MENU_ITEM]: 'Change theme',
  [LOGOUT_MENU_ITEM]: 'Logout',
  [OPEN_LEFT_PANE_ICON_TEXT]: 'Open left pane',
  [CLOSE_LEFT_PANE_ICON_TEXT]: 'Close left pane',

  // MyContent
  [VIDEOS_TITLE]: 'Videos',
  [MY_UPLOADS_TITLE]: 'My Uploads',
  [KEYWORDS_TITLE]: 'Keywords',
  [VIDEO_TITLE_DEFUALT_TEXT]: 'Video Title',
  [VIDEO_UPLOADING_PERCENTAGE_PHRASE]: (uploadPercent: number) => ` (Uploading at ${uploadPercent}%)`,
  [KEYWORDS_VIDEO_UPLOADING_STATE_TEXT]:
    'The keywords for this video will begin processing when the upload is complete.',
  [KEYWORDS_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT]:
    'Your keywords are in progress. Check back in couple of minutes and refresh the page.',

  // MyUploads
  [PREVIEW_COLUMN_LABEL]: 'Preview',
  [SHARED_WITH_COLUMN_LABEL]: 'Shared with',
  [RESOURCE_COLUMN_LABEL]: 'Resource',
  [VIDEO_DELETE_SUCCESS_MSG]: (count: number) => `${count} videos deleted successfully.`,
  [VIDEO_DELETE_FAILURE_MSG]: (count: number) =>
    `${count} videos failed to delete. For assistance please contact help@smartn.ca.`,
  [VIDEO_DELETE_TITLE_TEXT]: (count: number) => `Delete ${count} videos`,
  [VIDEO_DELETE_CONFIRMATION_MSG]: (count: number) => `Are you sure you want to delete following ${count} videos?`,
  [VIDEO_DELETE_TABLE_ARIA_LABEL]: 'table listing all the videos selected to be deleted',

  // OrbotAvatar
  [ORBOT_AVATAR_ALT]: 'Orbot Avatar',

  // Common
  [CANCEL_BUTTON_LABEL]: 'Cancel',
  [OK_BUTTON_LABEL]: 'OK',
  [DELETE_BUTTON_LABEL]: 'Delete',
  [STEP_1_OF_2_LABEL]: 'Step 1 of 2',
  [STEP_2_OF_2_LABEL]: 'Step 2 of 2',
  [DEFAULT_ERROR_MSG]:
    'An unexpected error occurred. Please reload the page and try again. Contact help@smartn.ca if the issue persists.',
  [MISSING_USERNAME_ERROR_MSG]: 'Missing username',
  [INVALID_USERNAME_WITH_SPACE_ERROR_MSG]:
    'Invalid username: username cannot contain spaces, tabs, or other whitespace',
  [MISSING_PASSWORD_ERROR_MSG]: 'Missing password',
  [PASSWORDS_DONT_MATCH_ERROR_MSG]: "Passwords don't match",
  [NEW_PASSWORD_FILED_LABEL]: 'New password (Required)',
  [NEW_PASSWORD_FILED_LABEL_2]: 'Confirm new password (Required)',
  [USERNAME_FIELD_LABEL]: 'Username (Required)',
  [EMAIL_FIELD_LABEL]: 'Email (Required)',
  [PASSWORD_FIELD_LABEL]: 'Password (Required)',
  [MISSING_CODE_ERROR_MSG]: 'Missing code',

  // utils
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER]: 'Password should contain at least one number',
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER]: 'Password should contain at least one lowercase character',
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER]: 'Password should contain at least one uppercase character',
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER]: 'Password should contain at least one special character',
  [INVALID_EMAIL_ERROR_MSG]: 'Invalid email',
  [USERNAME_TAKEN_ERROR_MSG]: 'Username taken, please try another username.',
  [INVALID_USERNAME_OR_PASSWORD_ERROR_MSG]: 'Incorrect username or password. Please try again.',
  [EXPIRED_CODE_OTP_ERROR_MSG]:
    'Oh no, your confirmation code is expired! Contact help@smartn.ca if the issue persists.',
  [EXPIRED_CODE_ERROR_MSG_1]: 'Oh no, your confirmation code is expired! You may request another code by selecting ',
  [EXPIRED_CODE_ERROR_MSG_2]: 'Cancel',
  [EXPIRED_CODE_ERROR_MSG_3]: ' on this page, and then request another code by selecting ',
  [EXPIRED_CODE_ERROR_MSG_4]: 'Forgot password?',
  [EXPIRED_CODE_ERROR_MSG_5]: ' Contact help@smartn.ca if the issue persists.',
  [CODE_MISMATCH_EXCEPTION_1]: 'The ',
  [CODE_MISMATCH_EXCEPTION_2]: 'Confirmation code',
  [CODE_MISMATCH_EXCEPTION_3]: ' is incorrect, please try again.',
  [NETWORK_ERROR_MSG]:
    'We cannot reach your network! Check your internet connection, and if the problem persists please contact your internet provider.',

  // Study space
  studySpace: {
    topTitle: 'Practice',
    backToSubjectView: 'Back to Subject View',
    partialTopicsChosen: (selected: number, total: number) => {
      if (selected === total) return `All ${total} Selected topics shown of this subject`;
      if (selected === 0) return 'No topics shown for this subject';
      return `${selected} of ${total} topics shown for this subject`;
    },
    showTopics: 'Show Topics',
    hideTopics: 'Hide Topics',
    learningContent: 'Learning Content',
    smartQuiz: 'Smart Quiz',
    material: 'Study Material',
    proficient: 'Proficient',
    selectAll: 'Select All',
    clearAll: 'Clear All',
    completion: 'Completed',
    noQuiz: 'No quiz started. Select topics to start a quiz',
    subtitle: 'Instant, intelligent and personalized practice that nurses deserve',
    allCategories: 'All Categories & Topics',
    filteredCategories: 'Filtered Categories & Topics',
    topicsTitle: 'Topics',
    cancel: 'Cancel',
    apply: 'Apply Changes',
    categoriesTitle: 'Categories',
    searchTopics: 'Search for a Category or Topic',
  },

  // Smart Quiz
  smartQuiz: {
    title: 'Bot',
    text:
      'Take a quiz to test your knowledge on this subject. Once you’re done, click on Submit Quiz to get an instant assessment and personalized Study Plan (Study Path) based on your current understanding.',
    buttonText: 'Start Quiz',
    question: 'Question',
    of: 'of',
    smartQuiz: 'Smart Quiz',
    checkAnswer: 'Check Answer',
    proceedTooltip: 'You must select an option to proceed',
    nextQuestion: 'Next Question',
    previousQuestion: 'Previous Question',
    correctAnswer: 'You answered correctly',
    incorrectAnswer: 'The correct answer is',
    yourAnswerWasCorrect: 'Your answer was correct',
    explanation: 'Explanation',
    viewQuizResults: 'View Quiz Results',
    youGot: 'You got',
    marksScored: 'marks in your last quiz being tested on:',
    correctConcepts: 'You did well in these topics',
    incorrectConcepts: 'You may have gaps in these topics',
    quizMeAgain: 'Quiz Me Again',
    reviewAnswers: 'Review Answers',
    youSelected: 'You Selected',
    reviewQuizzes: 'Review Past Quizzes',
    filtersModalTitle: 'Changing Topics?',
    filtersModalBody: ' Changing topics will take you to a new quiz. You can always resume where you left off.',
    cancel: 'Cancel',
    changeMySelections: 'Change My Topics',
    answerTitle: 'Answer',
    correctAnswerTitle: 'Correct Answer',
    rationale: 'Rationale',
    returnToDashboard: 'Return to Home',
    changeTopics: 'Change Topics',
    customizeQuiz: 'Customize Your Smart Quiz',
    exitModalTitle: 'Exit Smart Quiz?',
    exitModalBody: 'You can always resume where you left off when you return to doing this quiz.',
    exitModalConfirm: 'Exit Smart Quiz',
    customizeQuizHeading:
      'Generate a smart quiz of up to 20 questions personalized to you selected from 2,500+ NCLEX Style questions.',
    selectTopics: 'Select Topics',
    selectOneTopic: '(select at least one topic)',
    topicsTitle: 'Topics',
    categoriesTitle: 'NCLEX Exam Categories',
    clinicalJudgementTitle: 'NGN Clinical Judgement Models',
    topicsSelected: 'Topics Selected',
    topicsSubtitle: 'Select the Topics you want to be quizzed on in this quiz.',
    selectAll: 'Select All',
    clearAll: 'Clear All',
    searchTopics: 'Search for a topic...',
    mustSelect: 'You must select a topic and a type to proceed',
    categoriesSubtitle: 'Your selected filters will fall under the following Categories within the NCLEX Exam.',
    clinicalJudgementSubTitle: 'Your selected case study filters will have the following clinical judgement models',
    noTopics: 'No topics selected. Start by selecting topics you would like to be quizzed on',
    noCategories: 'This topic does not fall under any category',
    noClinicalJudgement: 'No clinical judgement models available for this topic',
    mustUpgradeButton: 'Upgrade',
    addonRequiredButton: 'Add SmartCLEX™ Now',
    addonRequiredFreeMember: 'Upgrade Now',
    addonText:
      "You can purchase the SmartCLEX™ Add-on for $29 USD after upgrading to the Smart'n Aspire membership plan",
    addonFreeMemberText: 'Purchase the SmartCLEX™ Add-on for $29 USD to continue',
    mustUpgrade: 'now to start',
    toContinue: 'now to continue',
    addToContinue: 'Add SmartCLEX™ now to continue',
    startCaseStudyButton: 'Start NextGen Case Study',
    continueToPart: 'Continue to Part ',
    returnToPart: 'Return to Part ',
    checkNgnAnswer: 'Check NGN Answers',
    selectType: 'Select question type',
    selectOneOption: '(select one of the following options)',
    multipleChoiceOption: 'Multiple Choice',
    ngnOption: 'NextGen Case Study Questions',
    bothOptions: 'Both Multiple Choice and NextGen Case Study Questions',
    skip: 'Skip',
    skipBaseline: 'Skip Baseline',
    skipQuestion: 'Skip Question',
    skipAndViewResults: 'Skip and View Quiz Results',
    arithmeticAnswerTitle: 'Answer:',
  },

  // Study Material
  studyMaterial: {
    uploadSources: 'Upload Sources',
    contentType: 'Content Type',
    allSources: 'All Sources',
    myUploads: 'My Uploads',
    orbitsContent: 'smart’n Content',
    allContent: 'All Content',
    documents: 'Documents',
    videos: 'Videos',
    audio: 'Audio',
    flashCards: 'Flashcards',
    upload: 'Upload',
    uploadTitle: 'Upload Content',
    uploadSubtitle: 'Upload File (Required)',
    dragAndDrop: 'Drag and drop file here or click to upload from your computer',
    or: 'or',
    uploadOrbitsTitle: 'Upload Content For Me',
    uploadOrbitsSubtitle: 'Upload smart’n A.I Recommended Study Materials',
    uploadFailed: 'Upload Failed',
  },
  reviewResults: {
    backToQuizResults: 'Back',
    reviewAnswers: 'Review Answers',
    reviewAnswersFor: 'Review Answers For',
    filterQuestions: 'Filter questions',
    allQuestions: 'All Questions',
    wrongAnswers: 'Wrong Answers',
    correctAnswers: 'Correct Answers',
    noCorrectAnswers: "You didn't get any questions correct. Try again!",
    noWrongAnswers: "You didn't get any questions wrong. Good job!",
    noQuestions: 'No answered questions to show. Try another filter from above.',
    multipleChoiceQuestions: 'Multiple Choice Questions',
    ngnCaseStudies: 'NGN Case Studies',
    nclexStyleQuestions: 'NCLEX Style Questions',
    questionTypes: 'Question Types',
    ngnCaseStudy: 'NGN Case Study',
    selectCaseStudy: 'Select Case Study',
    resultsTopicFilterButton: 'View Questions and answers',
    strongTopicsCard: "Good job on these topics'",
    quizStartDateLabel: 'Started on',
    quizEndDateLabel: 'Ended on',
    marks: 'marks',
    mark: 'mark',
    bowTie: 'Bow-Tie Question',
    topicAnalysis: {
      title: 'Topics Analysis',
      subTitleButton: 'Review Answers',
      readinessPieChart: {
        title: 'Category Breakdown',
        subTitle:
          'The NCSBN suggests each NCLEX exam consist of these categories +/- 3%. This % of categories gives you a realistic idea of the content breakdown on the actual NCLEX exam',
      },
      correctQuestionsBreakdown: 'Correct Questions By Topic',
      incorrectQuestionsBreakdown: 'Wrong Questions By Topic',
      masteryLevelDescription: (level: number) => {
        let percentage;
        switch (level) {
          case 4:
            percentage = '90% or more';
            break;
          case 3:
            percentage = 'between 70% and 90%';
            break;
          case 2:
            percentage = 'between 60% and 70%';
            break;
          case 1:
            percentage = 'between 50% and 60%';
            break;
          default:
            percentage = 'less than 50%';
        }
        return `You are level ${level} which means you got ${percentage} in this topic.`;
      },
    },
    topBanner: {
      marksScored: 'Marks Scored',
      passingChanceTag: 'Chance of Passing',
      totalTimeTaken: 'Total Time Taken',
      avgTime: 'Average time per question',
    },

    quizProgress: {
      leftLabel: 'Correct',
      rightLabel: 'Incorrect',
    },
    markingExplanations: {
      multipleChoice:
        'A Multiple Choice question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded if the correct choice is selected</li><li>0 is awarded if the wrong choice is selected</li></ul>',

      arithmetic:
        'An arithmetic question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded if the correct number is entered</li><li>0 is awarded if a wrong number is entered</li></ul>',
      multipleChoiceSelectAll:
        'A Multiple Response (Select all) question is marked based on the +/- approach. The +/- approach means:<ul><li>+1 is awarded for every correct choice selected</li><li>-1 is awarded for every incorrect choice selected</li><li>0 is awarded for every correct choice that is not selected</li><li>The minimum possible total mark is 0</li><li>The maximum possible total mark is the number of correct answers</li></ul>',
      [ECaseStudyQuestionTypes.SINGLE_CHOICE]:
        'An NGN Multiple Choice question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded if the correct choice is selected</li><li>0 is awarded if the wrong choice is selected</li></ul>',
      [ECaseStudyQuestionTypes.MULTIPLE_CHOICE_V2]:
        'An NGN Multiple Response (Select all) question is marked based on the +/- approach. The +/- approach means:<ul><li>+1 is awarded for every correct choice selected</li><li>-1 is awarded for every incorrect choice selected</li><li>0 is awarded for every correct choice that is not selected</li><li>The minimum possible total mark is 0</li><li>The maximum possible total mark is the number of correct answers</li></ul>',
      [ECaseStudyQuestionTypes.MATRIX_SINGLE_CHOICE]:
        'An NGN Matrix Multiple Choice question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded for any row where the correct choice is selected</li><li>0 is awarded for any row where the incorrect choice is selected</li></ul>',
      [ECaseStudyQuestionTypes.MATRIX_MULTIPLE_CHOICE]:
        'An NGN Matrix Multiple Response question is marked based on a column +/- approach. The column +/- approach means:<ul><li>A total mark is calculated for each column separateley</li><li>The total marks for all columns are added together to get the total question mark</li><li>For each column, +1 is awarded for every correct choice selected</li><li>-1 is awarded for every incorrect choice selected</li><li>0 is awarded for every correct choice that is not selected</li><li>The minimum possible total mark for a column is 0</li><li>The maximum possible total mark for a column is the number of correct answers for that column</li></ul>',
      [ECaseStudyQuestionTypes.MULTIPLE_HIGHLIGHT]:
        'An NGN Highlight Text question is marked based on the +/- approach. The +/- approach means:<ul><li>+1 is awarded for every correct choice selected</li><li>-1 is awarded for every incorrect choice selected</li><li>0 is awarded for every correct choice that is not selected</li><li>The minimum possible total mark is 0</li><li>The maximum possible total mark is the number of correct answers</li></ul>',
      [ECaseStudyQuestionTypes.DROP_DOWN_CLOZE]:
        'An NGN Drop-Down Cloze question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded for every drop-down with a correct choice selected</li><li>0 is awarded for every drop-down with an incorrect choice selected</li></ul>',
      [ECaseStudyQuestionTypes.DRAG_DROP_CLOZE]:
        'An NGN Drag-and-Drop Cloze question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded for every correct choice that is selected</li><li>0 is awarded for every wrong choice that is selected</li></ul>',
      [ECaseStudyQuestionTypes.BOW_TIE]:
        'An NGN Bow-Tie question is marked based on the 0/1 approach. The 0/1 approach means:<ul><li>1 is awarded for every correct choice that is selected</li><li>0 is awarded for every wrong choice that is selected</li></ul>',
    },
  },

  reviewPastQuizzes: {
    back: 'Back',
    pastQuizzes: 'Your Past Smart Quizzes',
    pastQuizzesSubtitle: 'Review and study your answers',
    completedOn: 'Completed On Nov 01, 2022 at 2:45 PM',
    youGot: 'You got',
    of: 'of',
    marks: 'marks being tested on',
    reviewAnswers: 'View Answers',
    continueQuiz: 'Continue Quiz',
    backToDashboard: 'Back to Dashboard',
    closeModal: 'Close',
    incomplete: 'Incomplete',
    baseline: 'Baseline Test',
    readiness: 'SmartCLEX Readiness Exam',
    showMore: 'Show More',
    noMoreQuizzes: 'You have no more quizzes',
  },

  signUp: {
    title: 'Sign Up',
    and: 'and',
    banner:
      'Experience instant and intelligent support tailored for nurses<span></span>Empowering you at every stage of your career',
    personlizedSection:
      '<span><span className="bold">Need to practice for the new NGN</span> Do realistic NGN-style Case Studies to practice <span className="underline"> your </span> clinical judgment.<span>',
    smartSection:
      '<span><span className="bold">Are you NGN ready?</span> Get formative NGN readiness assessment in real-time, personalized to <span className="underline">your</span> needs.<span>',
    starSection:
      '<span><span className="bold">Don’t know where to start?</span> Do a baseline assessment to identify <span className="underline">your</span> strengths and weaknesses.</span>',
    networkSection:
      '<span><span className="bold">Practicing NCLEX-style questions?</span> Quiz yourself from 2,500+ NCLEX-style questions customized based on <span className="underline">your</span> understanding of each NCLEX topic.</span>',

    form: {
      agreement: 'By signing up for smart’n, you agree to our',
      termsOfUse: 'Terms of Use',
      privacyPolicy: 'Privacy Policy',
      termsOfUseMinimal: 'Terms',
      privacyPolicyMinimal: 'Privacy',
      signInHere: 'Sign in Here',
      alreadyHaveAnAccount: 'Already a Member?',
      passwordHelper:
        'Password must include at least 8 characters, a special character, a number, and a capital letter',
      title: "Join the <span class='smartn'>smart<span class='smartn-apostrophe'>’</span>n</span> Community",
      submitText: 'Create Account',
    },
  },

  login: {
    title: "Welcome back - please sign in to continue using smart'n ",
    subTitle: "Please sign in to continue using smart'n",
    submitText: 'Log In',
    footerText: 'Not a member yet?',
    linkText: 'Become a member for free here',
    error: 'Incorrect username or password',
    userNameOrEmail: 'Your Email or Username',
  },

  forgotPassword: {
    title: 'Forgot your password?',
    subTitle:
      'Enter the email you registered with and if the email you entered matches one in our system, you’ll receive a 6 digit confirmation code which you can input to change your password.',

    submitText: 'Send Password Reset Code',
    back: 'Back',
    footer: 'Still having trouble?',
    footerLink: 'Send us a message',
  },

  setNewPassword: {
    title: 'Set your new password',
    subTitle:
      'After setting your new password, you will be redirected to the Login page and be required to log in again.',
    submitText: 'Save New Password',
    successTitle: 'Password successfully reset',
    successSubTitle: 'Please log in with your new password',
    successText: 'Log In',
  },

  form: {
    submitDefault: 'Save',
    forgotPassword: 'Forgot Password?',
    emailLabel: 'Your Email',
    passwordLabel: 'Your Password',
    usernameLabel: 'Username',
    requiredErrorMessage: 'is required',
    invalidEmail: 'Invalid email format',
    userNameIsEmail: "The username can't be an email address",
    invalidUserName: 'Username cannot contain spaces, tabs, or other whitespace',
    passwordOneNumber: 'Password should contain at least one number',
    passwordOneLowerCase: 'Password should contain at least one lowercase character',
    passwordOneUpperCase: 'Password should contain at least one uppercase character',
    passwordOneSpecialCharacter: 'Password should contain at least one special character',
    minLength: (chars: number) => `must be atleast ${chars} characters long`,
    passwordsNotMatching: "Passwords don't match",
  },

  resetPassword: {
    title: 'Input the passcode to proceed',
    subTitle: 'Password reset code sent to your email. Please input the 6 digit code to change your password.',
    submitText: 'Resend Code',
    error: 'Invalid Passcode. Please try again',
  },
  registerConfirmation: {
    title: 'Verify your email',
    subTitle: 'We sent a 6-digit code to your email. Please enter the code below.',
    submitText: 'Resend Code',
    error: 'Invalid Passcode. Please try again',
    resendSuccessTitle: 'A new confirmation code has been sent to your email.',
    resendSuccessSubTitle: 'Please input the 6 digit code to confirm your account.',
    confirmedSuccessTitle: 'Your email has been verified',
    confirmedSuccessSubTitle: 'please sign in to get started',
  },

  somethingWentWrong: 'Something went wrong! Please refresh the page.',
  errorBoundary: {
    title: 'Something went wrong',
    subTitleA: 'An unexpected error occured. Please try again.',
    subTitleB: 'For further inquiries please contact',
    buttonText: 'Go to Dashboard',
  },
  success: 'Completed successfully',
  pdf: {
    browserNotSupported: "Your browser doesn't support viewing PDF - here's a link",
    toThePdf: 'to the PDF!',
  },
  onBoarding: {
    title: 'Welcome to smart’n.',
    subTitleA: 'Let’s start personalizing your experience.',
    subTitleB: '',
    questionTitle: 'What are your objectives for using smart’n?',
    questionSubTitle: '(Select all that apply)',
    buttonText: 'Submit',
    disabledOneChoice: 'You must select and option to proceed',
    disabledExamChoice: 'Please fill in the required exam information',
    disabledNoShortAnswers: 'Please answer all questions',
    disabledNoAnswer: 'Please answer the question to proceed',
    next: 'Next',
    previous: 'Previous',
    footerHint: 'Or press Enter on your keyboard',
    hintFirstPart: 'You can also press Enter',
    hintSecondPart: 'on your keyboard to proceed to the next question each time.',
    selected: 'Selected',
    select: 'Select up to',
    topics: 'topics',
    of: 'of',
    topicsSelected: 'topics selected',
    clearAllSelections: 'Clear all selections',
    welcomeToSmartn: 'Welcome to smart’n {firstName}.\n Answer a few more questions to personalize your experience.',
    youCanSkip:
      'Or you can skip the remaining questions for now and answer them later as you explore all of the following features on Smart’n:<br><br> &nbsp•&nbsp NurseGPT<br> &nbsp•&nbsp Personalized Practice Quizzes<br> &nbsp•&nbsp Case Studies<br> &nbsp•&nbsp SmartCLEX Readiness Exams',
    letsDoThis: "Let's Do This",
    selectAll: 'Select all that apply to you',
    smartnBuddy: 'Let us know your smart’n Study Buddy here',
    standby: 'Standby, we are setting up your personalized experience ...',
    personalizing: 'Personalizing',
    whereToStart: 'You have been granted a sponsored Smart’n Aspire membership, where would you like to start?',
    location: "<span>You can find this under the <span className='purple'>STUDY </span>tab.</span>",
    personQuizTitle: 'Personalized Quiz',
    personQuizSubtitle: 'Start practicing with a personalized quiz based on your initial profile.',
    ngnTitle: 'NextGen Case Study',
    ngnSubtitle: 'Worried about NextGen NCLEX? Do a realistic NextGen Case Study.',
    baselineTitle: 'NCLEX Baseline Quiz',
    baselineSubtitle: 'Don’t know where to start studying? Do a NCLEX Readiness Baseline Quiz',
    readinessTitle: 'SmartCLEX™ Exam',
    readinessSubTitle: 'Jump into a simulated Exam experience and see how likely you are to pass the NCLEX.',
    nurseGPTTitle: 'NurseGPT',
    nurseGPTSubtitle:
      'A smart Chatbot that assists you in learning and answering any nursing question - personalized for you.',
    selectAdventure: 'Select where to start to proceed',
    startTrial: 'Get Started with Smart’n',
    upgrade: 'You can upgrade to full access anytime during or after your 30 day trial period.',
    fullAccess: (time: string) => `Your full access will end <span>tomorrow at ${time}.</span>`,
    skip: 'Skip',
    skipForNow: 'Skip For Now',
    choices: {
      writeNclexExam: {
        componentDetails: {
          text: 'When would you like to write your exam?',
          subText: ' Providing this info helps us to understand how to personalize your Learning Path.',

          options: {
            title: 'Choose one of the following options',
            rangePlaceHolder: 'Select a range',
            rangeLabel: 'Range',
            notSure: 'Not sure yet',
          },
        },
      },
      writePracticalNursingExam: {
        helperText: '(coming soon!)',
      },
    },
    preferredNameDefaults: {
      mainTitle: "Your email has been verified. Welcome to the smart'n community!",
      subTitle: '<div>To help us personalize your experience,<br>tell us a little bit about yourself</br></div>',
      subTitleMobile: '<div>To help us personalize your experience, tell us a little bit about yourself</div>',
      subTitleB: "Let's get started with your preferred name.",
    },
    ready: {
      title: 'Let’s make this a great day in your nursing journey',
      subTitleA: 'With your membership, you can enjoy access to:',
      SubTitleB:
        'Whether it’s accessing our reliable, on-shift support, keeping your skills sharp through professional development, or finding your next role, smart’n is making it easier to be a nurse.',
      SubTitleBStudent:
        'Whether it’s getting instant answers to your nursing questions, accessing our AI-driven NCLEX prep, or determining your readiness to write the NCLEX exam, smart’n is making it easier for you to become a nurse.',
      disclaimer: '*Subject to availability',
      studentlist: [
        {
          title: 'NurseGPT',
          subtitle: 'Improve your efficiency',
          details: '<span>Access unlimited support from<br/>your personal AI nursing assistant<span>',
          detailsMobile: '<span>Access unlimited support from your personal AI nursing assistant<span>',
        },
        {
          title: 'Study',
          subtitle: 'Optimize your learning',
          details: '<span>Focus on what matters. Pass your<br/>tests and conquer the NCLEX exam<span>',
          detailsMobile: '<span>Focus on what matters. Pass your tests and conquer the NCLEX exam<span>',
        },
        {
          title: 'Apply*',
          subtitle: 'Find the right job for you',
          details: '<span>Get early access to jobs that are<br/>aligned to your values</span>',
          detailsMobile: '<span>Get early access to jobs that are aligned to your values</span>',
        },
      ],

      list: [
        {
          title: 'NurseGPT',
          subtitle: 'Improve your efficiency',
          details: '<span>Access unlimited support from<br/>your personal AI nursing assistant<span>',
          detailsMobile: '<span>Access unlimited support from your personal AI nursing assistant<span>',
        },
        {
          title: 'Study',
          subtitle: 'Optimize your learning',
          details: '<span>Refresh and assess your<br/>knowledge and clinical judgment<span>',
          detailsMobile: '<span>Refresh and assess your knowledge and clinical judgment<span>',
        },
        {
          title: 'Apply*',
          subtitle: 'Find the right job for you',
          details: '<span>Get early access to jobs that are<br/>aligned to your values</span>',
          detailsMobile: '<span>Get early access to jobs that are aligned to your values</span>',
        },
      ],

      preButton:
        "<span>Welcome to <span className='smartn'>smart<span className='apostropohe'>’</span>n</span></span></span>",
      buttonText: 'I’m ready, let’s go',
    },
  },

  datePicker: {
    month: 'Month',
    year: 'Year',
    buttonConfirm: 'Apply',
    buttonCancel: 'Cancel',
    placeHolder: 'Select a specific date',
  },
  //Want more content like this? Upgrade to access more
  //features to help you accelerate your studying for NCLEX
  placementTest: {
    unstartedQuiz: {
      title: 'Complimentary Baseline Quiz',
      text:
        '<div> <div className="text-div">This baseline quiz includes a real-life NGN case study question and multi-topic NCLEX practice questions.  You will get an immediate assessment of strengths and weaknesses and initiate the process of tailoring your personalized study experience.</div> <div className="text-div" >It typically takes 5 to 15 minutes to complete. If you can not complete it now, remember that you can log back in at any time to finish up.</div><div>Ready to start?</div> </div>',
      buttonText: 'Start Quiz',
    },
    smartQuiz: {
      title: 'Baseline Test',
    },
    resultsButtonText: 'Go to dashboard',
    upgradeTitleHighlight: () => 'Upgrade now',
    upgradeTitleTriallingAfterHighlight: "to Smart'n. Upgrade now to continue enjoying Smart'n after your trial ends",
    buttonText: 'Learn More',
    comingSoonText: ' (coming soon)',
    upgradeInfo: {
      trialling: {
        header: 'Join the modern nursing community',
        subTitleA: 'You have 30 days access to almost every feature on',
        subTitleB:
          "To experience AI-enabled support after your free trial, become a Smart'n Aspire member for less than a trip to the coffee shop.",
      },
      freeMember: {
        header: 'Join the modern nursing community',
        subTitleA: 'Your free trial has ended',
        subTitleB:
          "To experience AI-enabled support, become a Smart'n Aspire member for less than a trip to the coffee shop.",
      },
    },
    infoTrialling: [
      {
        bold: 'Challenging NGN Case Studies',
        text: 'just like they will appear on the exam',
      },
      {
        bold: 'Personalized quizzes',
        text: 'as assessments tailored for you',
      },
      {
        bold: '2,500+ NCLEX  ',
        text: 'style practice questions with rationales',
      },
      {
        bold: '',
        text: 'Track your progress towards NCLEX Readiness',
      },
    ],

    info: [
      { bold: '2,500+ ', text: 'NCLEX practice questions & detailed rationales.' },
      {
        bold: 'Practice & Assess Your Critical Thinking + Clinical Judgement',
        text: 'through next-generation (NGN) NCLEX style case studies',
      },
      {
        bold: 'Assess your strengths',
        text: 'and identify gaps with unlimited AI-powered knowledge assessments each time you complete a quiz.',
      },
      { bold: 'Track your progress', text: 'for each category or topic, so you know what you need to study' },
    ],
  },

  dashboard: {
    watchTitle: 'Only Watch and Listen to What You Need',
    watchParagraph:
      'Upload video/audio files to have them instantly transcribed and searchable to make it easy to watch and listen to the specific content you need to find.',
    answersTitle: 'Find Answers Faster',
    answersParagraph:
      'Upload your textbook or any pdf to have all your study materials in one place and searchable so you can find the answers you need in seconds.',
    quizYourself: 'Quiz Yourself',
    uploadContent: 'Upload Content',
    subjectSubtitle: 'Based on the results of all your quizzes, this is where you stand',
    goToStudySpace: 'Go to Study Space',
    viewStudyMaterials: 'View Study Materials',
    recommend: 'We recommend you work on these topics',
    doingGreat: 'So far, you have done well in these topics',
    doingGreatTooltip: "Taking more quizzes will help us better evaluate topics you're doing well in",
    quizOnTopic: 'Start Smart Quiz on this Topic',
    materialForTopic: 'View Study Material for Topic',
    welcomeMessage: 'Your Recommendations and Progress,',
    quizzesTaken: 'Quizzes taken',
    questionsAnswered: 'Questions answered',
    topicsQuizzed: 'Topics you were quizzed on',
    proficiency: 'Overall Proficiency',
    viewPastQuizzes: 'View Past Quizzes',
    quizOnAllTopics: 'Start Smart Quiz with all of these topics',
    role: 'Role',
    selectARole: 'Please select a role',
    membership: 'Membership',
    freeMember: 'Trial Ended',
    defaultGoal: 'General Study',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    nurseGPT: {
      nurseGPTTitle: 'NurseGPT',
      nurseGPTBody: 'Instant answers, tailored support, and create aids specific to nursing',
      beta: 'BETA',
      askNursingQuestion: 'Ask NurseGPT',
      waitlist: 'NurseGPT Waitlist',
      summary:
        "NurseGPT is a conversational AI tool that you can ask questions of to help you with learning, studying, and practicing. To ensure the best user experience, we've reached the maximum number of users with access. Want to be next in line?",
      optional: 'Optional',
      join: 'Join the waitlist to get early NurseGPT access',
      thanks:
        'You’ve been added to the waitlist. Thanks for expressing your interest in NurseGPT. We’ll reach out when your access is ready.',
      back: 'Back to Home',
      text: 'A smart Chatbot that assists you in learning and answering any nursing question - personalized for you.',
    },
    freeBanner: {
      buttonText: 'Upgrade',
      text: 'today for more NGN case studies and your personalized NCLEX study experience.',
    },
    personalizeQuiz: {
      title: 'Personalized Quiz, Just for You',
      subTitle: 'Have Smart’n generate a quiz with questions from topics our A.I. recommends you practice.',
      startButton: 'Take Personalized Quiz',
      noTopicsFound: 'You have no weak topics yet',
    },

    quickLinks: {
      title: 'Quick Links',
    },

    readinessAssesement: {
      title: 'SmartCLEX',
      subTitle: 'Preparing for your NCLEX?',
      startButton: 'Add Now',
      inactive: {
        body: 'Are you NCLEX Ready?',
        startButton: 'Add now for only $29',
        subText: 'AI-powered simulation test to assess gaps and the likelihood you’ll pass.',
      },
      active: {
        body:
          'Ace the NCLEX Exam with our AI-powered SmartCLEX™ designed for personalized assessment and exam success.',
        startButton: 'Start SmartCLEX™',
      },
      continue: 'Continue Exam',
      viewResults: 'View Results',
      startExam: 'Start Exam',
      startNewExam: 'Start New Exam',
      tagLabel: 'Chance of Passing',
      tags: {
        low: 'Low',
      },
      attemptTitle: `Questions, 3 hours duration`,
      progressTitle: '% Completed',
      examName: 'NCLEX Readiness Exam',
      infoTitle: 'Want to..',
      infoList: [
        'know if you will pass?',
        'know which topics to focus on?',
        'unlock more NGN questions?',
        'unlock more NGN case studies?',
      ],
      button: 'Add Now',
    },
    practiceQuiz: {
      title: 'Practice Quiz',
      continueTitle: 'Continue where I left off',
      button: 'Continue',
      continueButtonTitle: 'View Incompleted Quizzes',
      startQuiz: {
        title: 'Start A New Quiz',
        subTitle:
          'Generate a smart quiz of up to 20 questions personalized to you selected from 2,500+ NCLEX Style questions',
        questionTitle: 'What type of questions would you like to do?',
        quizPrompt: 'Select one or more topics for the practice quiz',
        weakTopics: 'Topics We Suggest You Work On',
        limitedTopics: 'Additional Topics to Explore',
        strongTopics: 'Topics You Have Done Well On',
        multipleChoiceOption: 'Multiple Choice Only',
        ngnOption: 'NextGen Case Study Only',
        allOptions: 'Both NCLEX Style and NextGen Case Studies',
        nclexQuestions: 'NCLEX Style Questions',
        noTopicsFound: 'No topics found',
        selectAllButton: 'Select All',
        selectAllHeaderButton: 'Select All Topics',
        deselectAll: 'Deselect All',
        button: 'Start Practice Quiz',
        infoIconWeak: 'We assess these based on the Smart Quizzes you took so far.',
        infoIconLimited: 'Explore these topics to assess your current level of knowledge.',
        infoIconStrong: 'Great job with these topics. Keep practicing with them to further improve your understanding.',
        mustSelect: 'You must select a topic and a type to proceed',
        numberSelected: (num: number) => `${num} ${num > 1 ? 'Topics' : 'Topic'} selected`,
        clearButton: 'Clear all selections',
      },
      nclex: {
        title: 'Prepare for your NCLEX ',
        examTitle: 'SmartCLEX™',
        examSubTitle:
          'Ace the NCLEX Exam with SmartCLEX™, our AI-powered Readiness Exam designed for personalized assessment and exam success.',
        examInsightsButton: 'SmartCLEX Insights',
        marks: 'Correct Marks',
        time: 'Total Time Taken',
        quizCardSubTitle: '100 Questions, 3 hours duration',
        reviewAnswers: 'Review Answers',
        continueButton: 'Continue Exam',
        start: 'Start Exam',
        innerTitle: 'Start Another',
        defaultTitle: 'SmartCLEX Exam',
      },
    },
    reviewPastQuizzes: {
      title: 'Review Your Past Quizzes & Rationales',
      reviewAll: 'Review All Quizzes',
      innerTitle: 'Your Most Recent Quizzes',
      notFoundMessage: 'No completed quiz found.',
      reviewAttempt: 'Review Insights',
      toolTip: 'This feature will be available once you’ve taken your first quiz',
      noQuizzesTitle: "You haven't taken any quizzes yet in the past month",
      noQuizzesSubTitle: 'Start a quiz to have an understanding of your knowledge across different topics.',
      startQuizbutton: 'Start A Quiz',
    },
    analyze: {
      title: 'Analyze',
      button: 'Insights',
      strongTopics: 'Topics You Are Strong In',
      weakTopics: 'Topics To Work On',
      innerTitle: 'Topic',
      quizOnTopic: 'Start a Quiz with this Topic',
      caseStudyOnTopic: 'Start a Case Study with this Topic',
      noTopicsTitle: (isStrong: boolean) => `You have no ${isStrong ? 'strong' : 'weak'} topics yet`,
      noTopicsSubTitle: 'Start a quiz to have an understanding of your knowledge across different topics.',
      startQuizbutton: 'Start A Quiz',
    },
    assess: {
      title: 'Assess Your Knowledge',
      baselineQuiz: {
        title: 'Baseline Quiz',
        description:
          '14 high-yield NCLEX topic questions, which assess your learning gaps, provide in-depth topic analysis, and tailor to your studying needs.',
        button: 'Take The Baseline Quiz',
        buttonContinue: 'Continue Quiz',
        buttonDone: 'Review Your Results',
      },

      readiness: {
        title: 'SmartCLEX™ Exam',
        description:
          'Ace the NCLEX Exam with SmartCLEX™, our AI-powered Readiness Exam designed for personalized assessment and exam success.',
        button: 'Take the SmartCLEX™ Exam',
      },
    },
    paymentIssueModal: {
      title: 'There is an issue with your payment',
      content: 'The credit card stored is invalid. Please update your payment information to continue your membership.',
    },

    trialEnded: {
      info: [
        {
          bold: '',
          text: '2,500+ practice questions (including NextGen) + concise rationales.',
        },
        {
          bold: '',
          text: 'Assess your strengths and identify gaps.',
        },
        {
          bold: '',
          text: 'Track your progress so you know what you need to study.',
        },
      ],

      upgradeTitleBeforeHighlight: 'Want more content like this? Upgrade to access more features to help',
      upgradeTitleHighlight: 'accelerate your studying',
      upgradeTitleAfterHighlight: 'for NCLEX',
    },

    caseStudyTopics: {
      title: 'Popular NGN Case Studies',
      // subTitle:
      //   'Skip the customization process and jump right into a NCLEX Case Study for any of the following topics:',
    },

    popularQuizzes: {
      title: 'Popular Quizzes',
      cardTitle: "Recommended by smart'n",
    },
    pastQuizzes: {
      title: 'Past Quizzes',
      button: 'View all',
      andMore: (number: number) => `and ${number} more`,
      notFoundMessage: 'No incomplete quizzes found',
    },

    //   navHeader: {
    //     sections: ['Practice', 'Assess', 'Review', 'Analyze'],
    //     nurseGPT: 'Ask NurseGPT',
    //   },
    // },
    navHeader: {
      nurseGPt: {
        label: 'Ask NurseGPT',
      },
      study: {
        label: 'Study',
      },
      apply: {
        label: 'Apply',
      },
      secondary: ['Practice', 'Assess', 'Review', 'Analyze'],
      secondaryMobile: ['Practice', 'Assess', 'Review', 'Analyze', 'Apply'],
    },
  },
  billing: {
    title: 'Want to continue your personalized NCLEX study experience?',
    subtitle: 'Upgrade now for unlimited NGN case studies and practice questions.',
    upgradeTitle: 'Join the modern nursing community',
    upgradeSubTitle: 'Experience AI-enabled learning support with the',
    upgradeSubtitleRight: 'membership',

    // plans: [
    //   {
    //     key: 'monthly',
    //     title: '30-day Access',
    //     outerTitle: 'Access',
    //     priceId: '',
    //     price: 9,
    //     headLine: 'The Basic Membership provides you with access to the core features on',
    //     headLineDisclaimer: '* 90-day and annual options only',
    //     promotion: '',
    //     period: '30 Days',
    //     periodDescription: '$39 billed every month',
    //     priceDescription: '/month',
    //     benefits: [
    //       '<p>Hours of realistic <span>NGN NCLEX case studies</span> for every topic</p>',
    //       '<p><span>2500+</span> NCLEX style questions + rationales</p>',
    //       'Real-time knowledge assessments powered by AI',
    //       'Personalized smart quizzes and study recommendations',
    //       'Baseline Quiz',
    //     ],
    //   },
    //   {
    //     key: 'triMonthly',
    //     priceId: '',
    //     title: '90-day Access',
    //     price: 79,
    //     headLine: '<p>100% NCLEX Pass Guarantee + $30 cash when you pass*<p>',
    //     headLineDisclaimer: '* 90-day and annual options only',
    //     promotion: '',
    //     period: '90 Days',
    //     periodDescription: '$79 billed every 90 Days',
    //     benefits: [
    //       '2,500+ NCLEX Style Practice Questions',
    //       'NGN Case Studies for every topic',
    //       'A.I. powered knowledge assessment',
    //       'Unlimited quizzes',
    //     ],
    //   },
    //   {
    //     key: 'yearly',
    //     priceId: '',
    //     title: 'All Year Access',
    //     price: 119,
    //     isRecommended: true,
    //     headLine: '<p>100% money back guarantee <span>PLUS</span> $30 cash back when you pass.<p>',
    //     promotion: '',
    //     period: '1 Year',
    //     periodDescription: '$119 billed yearly',
    //     benefits: [
    //       '2,500+ NCLEX Style Practice Questions',
    //       'NGN Case Studies for every topic',
    //       'A.I. powered knowledge assessment',
    //       'Unlimited quizzes',
    //     ],
    //   },
    // ],
    sponsored: 'Sponsored',
    planButtonText: 'Join Now',
    planLandingButtonText: 'Join The Modern Nursing Community',
    currentlyOn: () => `You are currently subscribed`,
    cancel: 'Cancel Membership',
    upgradedConfirmModal: 'Get Started',
    cancelModalTitle: "We're sorry to see you go",
    cancelModalButton: 'Cancel My Membership',
    accountUpgraded: 'Aspire Membership Activated',
    membershipEndsOn: 'Ends on',
    accountResubscribed: 'Aspire Membership Resubscribed',
    accountUpgradedTo: () => `Aspire Membership Activated`,
    close: 'Close',
    wantToCancel: (endDate: string) =>
      `Are you sure you want to cancel your membership? Your membership will remain active until your billing period ends on <span className="bold">${endDate}<span>.`,
    accountResubscribedContent: (endDate: string) =>
      `Your membership will continue as planned. The next billing date is on <span className="bold">${endDate}<span>.`,
    nevermind: 'Keep My Membership',
    cancelled: 'Membership Cancelled',
    subscriptionHasBeenCancelled: (endDate: string) =>
      `Your membership is now cancelled. Your account will remain active until your billing period ends on <span className="bold">${endDate}<span>.`,
    comingSoon: 'Coming Soon!',
    invalidAddressError: 'Address is invalid. Please try again with a different address.',
    invalidPromoCodeError: 'Promotion code is invalid',
    paymentForm: {
      title: 'Manage Membership',
      email: 'Email',
      card: 'Card Number',
      name: 'Name on card',
      calculatePrice: 'Check Total Price',
      pay: 'Pay Now',
      cancel: 'Cancel',
      close: 'Close',
      poweredBy: 'Powered by',
      address: 'Billing Address',
      requiredFields: 'Please fill in the required fields to checkout',
      requiredFieldsPaymentMethod: 'Please fill in the required fields to proceed',
      line1: 'Line 1',
      city: 'City',
      zipCode: 'ZIP',
      promoCode: 'Add a promotion code',
      changePromoCode: 'Change promotion code',
      promoCodeLabel: 'Promotion Code',
      selectPrice: 'Please select a subscription type',
      countries: [
        { value: 'US', label: 'United States' },
        { value: 'CA', label: 'Canada' },
      ],
      apply: 'Apply',
      leftSectionTitle: 'Choose your subscription type',
      titlePayment: 'Payment Method',
      titleTop: 'Your Membership Plan',
      planSubTitleSponsored: 'Automatically renews for free on',
      planSubTitleMember: 'Next payment will be billed on',
      planSubTitleTrial: 'Your trial ends on',
      landingSubscibedTrial: 'ends',
      landingSubscribedActive: 'renews',
      landingSubscribedSponsored: "You are currently on the Smart'n Aspire Sponsored Membership",
      notSubscribed: 'You currently do not have access',
      planRightTitle: 'Aspire',
      paymentMethodButton: 'Edit',
      changePaymentMethod: 'Update Payment Method',
      paymentMethodChanged: 'Payment method has been changed',
      paymentMethodChangedWithPaymentIssue:
        "Payment method has been changed. We'll attempt to renew your subscription soon.",
      noPaymentMethodAvailable: 'No payment method available',
    },
    paymentIssueTitleMessage: 'Membership renewal failed',
    paymentIssueSubTitle: 'Please update your credit card information to continue your membership',
    paymentIssueAction: 'Update Now',
    deprecatedSubscriptionTitle: 'Your current subscription is deprecated',
    deprecatedSubscriptionSubTitle: `
      <span>
        Please contact <a href="mailto:connect@smartn.ca">connect@smartn.ca</a> to change your subscription. New
        subscriptions start at $9 /month USD.
      </span>`,
    deprecatedSubscriptionStatusTag: `Change Required`,
    update: 'Update Payment Method',

    subTotal: 'Sub Total',
    taxes: 'Taxes',
    total: 'Total',
    discount: 'Discount',
    landingTitle: 'Time-saving tools and study practice tailored to you and your budget.',
    landingSubTitle: 'Upgrade now for unlimited NGN case studies and practice questions.',
    landingSubscribed: {
      title: 'Membership & Add-Ons',
      subTitle: 'Time-saving tools and study practice tailored to you and your budget.',
      popupTitle: 'Your Membership Plan',
      popupButton: 'Manage Membership',
      status: {
        active: 'Active',
        paymentIssue: 'Payment Issue',
        cancelled: 'Cancelled',
      },
      addonsTitle: 'Membership Add-ons',
      addonsSubTitle: 'Personalize your experience with the below membership add-ons',
      addon: 'ADD-ON',
      oneTimeFee: 'one-time addition',
      button: 'Activate for',
      buttonActivated: 'Activated',
      resubscribe: 'Resubscribe',
      deprecatedSubscriptionSubTitle: `
        <span>
          Please contact <a href="mailto:connect@smartn.ca">connect@smartn.ca</a> or cancel the current subscription and
          wait until the end of the billing period to change your subscription to the new
        </span>
        `,
      deprecatedSubscriptionSubTitleRight: 'Membership for $9 /month USD',
      cancelledMeta:
        'Your membership is now cancelled. Your account will remain active until your billing period ends on',
      cancelledMetaDeprecated:
        "Your membership is now cancelled. You can upgrade to the Smart'n Aspire membership once your billing period ends on",
      paymentIssueMeta:
        'Membership renewal failed. Please update your credit card information to continue your membership.',
      cancelledMetaDeprecatedRight: 'Your account will remain active until then.',

      deprecatedAddonButton: 'Included with Basic Access',
    },
    addonsMembershipInfoOneTime:
      'This add-on will be unlocked and available for you throughout the duration of your active membership',
    addonConfirmationButton: 'Purchase Add-on',
    addonSuccessTitle: "You've unlocked",
    addonsTitle: 'Unlock',
    addonsTopTitle: 'Unlock add-on',
    addonPurchased: "You've unlocked",
    addonPurchasedContent:
      'This add-on will be unlocked and available for you throughout the duration of your active membership',
  },

  header: {
    freeBannerBeforeButtonText: 'Your free',
    freeBannerAfterLogoText: 'Trial has ended.',
    //boldText: 'Starter Member',
    freeBannerButtonText: 'Join now',
    freeBannerAfterButtonText: "to continue enjoying Smart'n features.",
    defaultTrialText: 'Your free',
    sponsoredTrialTextA: 'You are currently on a sponsored',
    sponsoredTrialTextB: 'membership - $9/monthly value',
    ending: 'before your trial',
    endsIn: 'ends in',
    ended: 'ended',
    defaultEnding: " Your trial ends soon - ugrade to keep enjoying smart'n",
    Hours: 'Hours',
    Minutes: 'Minutes',
    Seconds: 'Seconds',
    Now: 'now',
    Days: 'Days',
    enhanceBannerBeforeButtonText: 'Visit the',
    enhanceBannerButtonText: 'Apply',
    enhanceBannnerAfterButtonText: 'page to enhance your profile and get connected to the right roles!',
    enhanceBannerIncompleteAfterButtonText:
      'page to complete your enhanced profile and get connected to the right roles!',
    profileMenu: {
      settings: 'Account Settings',
      membership: 'Membership Plan',
      signOut: 'Sign Out',
      completeProfile: 'Complete Profile',
      takenQuizzes: 'Quizzes Taken',
      answeredQuestions: 'Questions Answered',
      topics: 'Topics Quizzed on',
    },

    undergradBanners: [
      {
        text: 'Writing your NCLEX exam soon? Kickstart your career with our',
        button: 'AI-based job matching',
        textSuffix: undefined,
        id: EQuestionIDS.U1,
      },

      {
        text: 'NCLEX around the corner? Start strong in your nursing career.',
        button: 'Learn more',
        id: EQuestionIDS.U2,
      },

      {
        text: 'Completing your NCLEX soon? Get a head start on your',
        button: 'nursing career',
        id: EQuestionIDS.U3,
      },

      {
        text: 'Ready for your next nursing opportunity?',
        button: 'Learn more',
        id: EQuestionIDS.U4,
      },
    ],

    gradBanners: [
      {
        text: 'Looking for the next step in your career?',
        button: 'Learn more',
        textSuffix: 'about how we can help!',
        id: EQuestionIDS.G1,
      },

      {
        text: 'Ready for your next nursing opportunity?',
        button: 'Learn more',
        id: EQuestionIDS.G2,
      },
      {
        text: 'Looking for a change? Discover our',
        button: 'AI-based job matching ',
        id: EQuestionIDS.G3,
      },
      {
        text: 'New nursing opportunities await!',
        button: 'Learn more',
        id: EQuestionIDS.G4,
      },

      {
        text: 'Looking for a change?',
        button: 'Discover new career opportunities',
        id: EQuestionIDS.G5,
      },

      {
        text: 'Your career, your values.',
        button: 'Learn more',
        textSuffix: 'about our job matching program',
        id: EQuestionIDS.G6,
      },

      {
        text: 'Align your career with your values.',
        button: 'Get started',
        textSuffix: 'with our AI-based job matching',
        id: EQuestionIDS.G7,
      },

      {
        text: 'Seeking a fresh start? Discover our',
        button: 'AI-based job matching ',
        id: EQuestionIDS.G8,
      },

      {
        text: 'Find the right nursing role for you.',
        button: 'Get started',
        id: EQuestionIDS.G9,
      },
    ],
  },

  seoTags: {
    titles: {
      login: "Smart'n | Login",
      signup: "Smart'n | Register",
      dashboard: "Smart'n | Dashboard",
      resetPassword: "Smart'n | Reset Password",
      forgetPassword: "Smart'n | Forget Password",
      changePassword: "Smart'n | Change Password",
      registerConfirmation: "Smart'n | Register Confirmation",
      onboarding: "Smart'n | Onboarding",
      placementTest: "Smart'n | Baseline Test",
      studySpace: "Smart'n | Study Space",
      membership: "Smart'n | Subscriptions",
      readinessAssesment: "Smart'n | Readiness Assesment",
      reviewResults: "Smart'n | Review Results",
      home: "Smart'n | Home",
      apply: "Smart'n | Apply",
    },
  },
  caseStudy: {
    ngnCaseStudy: (current: number, total: number) => `NGN Case Study - Part ${current} of ${total}`,
    select: 'Select...',
    and: 'and',
    wordChoice: 'Word Choice',
    wordChoices: 'Word Choices',
    action: 'Action to Take',
    actions: 'Actions to Take',
    condition: 'Condition Most Likely Experiencing',
    conditions: 'Potential Conditions',
    parameter: 'Parameter to Monitor',
    parameters: 'Parameters to Monitor',
    smartnTip: 'smart’n Tip',
    unstartedTitle: 'NextGen Case Study Question',
    unstartedTitlePlacement: 'NextGen Case Study Simulation',
    unstartedFirstBody: (total: number) =>
      `You will be taking a multi-part case study question that has ${total} parts to it.`,
    unstartedFirstBodyPlacement:
      'This sample case study from our library spans all NCLEX topics and is a realistic example of the type of case study presented on test day.',
    unstartedSecondBodyPlacement:
      'Like your NCLEX, It is a multi-part case study with up to 6 questions. Follow the instructions on the screen, and be sure to check our helpful tips. Once completed, you will have a chance to review your results and rationales for correct and incorrect answers.  Our AI engine will track your results to determine your NCLEX readiness.',

    unstartedSecondBody:
      'Once you selected your answer and proceeded to the next part, you won’t be able to return and modify your previous answers.',
    unstartedThirdBody: 'At the end of the Case Study, you will be able to review all your answers and rationales.',
    rationale: 'Rationale',
    correctAnswers: 'Correct Answers',
    noModify: "Answers submitted can't be modified",
    endedTitle: 'NGN Case Study Completed',
    endedTitlePlacement: 'NGN Case Study Question Completed - Nice work!',
    endedFirstBodyPlacement: 'Our AI engine is already busy building your personalized study experience!',
    endedSecondBodyPlacement:
      'You can check your answers (with rationales) or proceed to the next question in the baseline quiz.',
    endedBody:
      'You can now proceed to the next question. You will get your marks for this NGN Case Study after you finish the quiz.',
    mobile: {
      expandablesOptionsLabel: 'Options',
    },
    bowTieInfo: 'Hover or touch an item to display the full text, incase it is not displayed fully (...)',
    mark: 'mark',
    marks: 'marks',
    of: 'of',
  },

  components: {
    noContentFound: {
      defaultMessage: 'No content found',
    },
  },
  oAuth: {
    signUpWithGoogle: 'Sign up with Google',
    signInWithGoogle: 'Sign in with Google',
  },
  comingSoon: {
    title: 'Coming Soon ...',
    subTitle: `We’re still working on the final details, but if you want to be one of the first to try SmartCLEX™, type “sign me up” in the help chat in the bottom right corner.`,
    button: 'Return to Home',
  },
  readinessAssesment: {
    unstartedQuiz: {
      title: 'Ready to start your SmartCLEX™ Readiness Exam?',
      features: [
        {
          icon: 'Watch',
          text: 'The SmartCLEX™ Readiness Exam is a test mode of smart’n that can be paused and resumed as needed',
        },
        {
          icon: 'PenPaper',
          text:
            'All the answers and rationales will be presented at the end of the SmartCLEX™, once you have finished the exam. You will not be able to go back to previous answers or change answer options',
        },
        {
          icon: 'Graph',
          text:
            'The questions on this SmartCLEX™ will consist of traditional and NextGen NCLEX style questions. The SmartCLEX™ has been developed using similar percentages of NCLEX categories The National Council of State Boards Nursing (NCSBN) recommends for every NCLEX exam',
        },
      ],
      startButton: 'Start SmartCLEX™',
      goBack: 'Return to Home',
      footerNote: 'You have full access to complete, review your exam simulation results and analysis.',
    },
    passingChanceDisclaimerDashboard:
      "Based on your performance in readiness exam attempts of the last month. This is only an <span className='underline bold'>indicator</span>.",
    passingChanceDisclaimerReviewResults:
      "Based on your performance in all questions and each topic score. This is only an <span className='underline bold'>indicator</span>.",

    buttons: {
      exit: 'Exit NCLEX Readiness Exam',
      exitmodalTitle: 'Exit this NCLEX Readiness Exam?',
      exitModalConfirmation: 'Exit NCLEX Readiness Exam',
    },
  },
  nurseGPT: {
    title: 'NurseGPT',
    beta: 'BETA',
    initialPlaceholder: 'Type a nursing request or question, or click an example',
    ongoingPlaceholder: 'Type a follow-up question or request to keep going',
    initialMobilePlaceholder: 'Type a nursing request or question',
    ongoingMobilePlaceholder: 'Type a follow-up request',
    you: 'You',
    unstartedChatTitle: 'How can I support your nursing goals today?',
    disclaimer: 'Verify NurseGPT responses for accuracy on important or sensitive topics',
    generatingResponse: 'Generating response ',
    noSessionsTitle: 'No Sessions Yet',
    noSessionsBody: 'All your sessions will appear here. Start a new session by asking NurseGPT a nursing question.',
    about: 'About NurseGPT',
    firstAboutParagraph: 'Get instant answers, tailored support, and create aids specific to nursing',
    secondAboutParagraph:
      'While NurseGPT is proficient, you should verify NurseGPT responses for accuracy on important or sensitive topics.',
    close: 'Close',
    rolePrefix: "I'm asking as a",
    cancel: 'Cancel',
    deleteSession: 'Delete Session',
    deleteSessionTitle: 'Delete Session?',
    willDelete: 'This will delete the session ',
    cantUndone: ' and can’t be undone.',
    deleteButton: 'Delete',
    renameSession: 'Rename Session',
    mobileRolePrefix: 'Asking as a',
    somethingWrong: 'Something went wrong',
    trouble: 'We are having trouble connecting with our servers. Please refresh the page and try again.',
    refreshButton: 'Refresh',
    copy: 'Copy',
    copied: 'Copied',
    // examples: [
    //   {
    //     title: 'I am preparing for my NCLEX exam. Make me a 60 day study plan',
    //   },
    //   {
    //     title:
    //       'I am a practicing nurse. Suggest 3 community-based interventions to promote healthy behaviors and prevent diabetes',
    //   },
    //   {
    //     title: 'I am a nursing student. If I add my study notes, can you create 20 questions for me to practice?',
    //   },
    //   {
    //     title: 'I am a practicing nurse. What are the nursing considerations of a patient with heart disease?',
    //   },
    // ],
    prompts: {
      student: {
        nclexStudySupport: [
          'I am a nursing student. Create an NCLEX study plan for my exam in 2 months where I have 2 hours to study each day.',
          'How should I best answer bow-tie questions on the NCLEX?',
          'How can I overcome test anxiety for the NCLEX?',
          'Write 10 NCLEX style pharmacology questions with answers at the end.',
          'Provide strategies on how to effectively study content heavy topics, such as pharmacology, for the NCLEX.',
          'Is the interpretation of a fetal heart monitor tested on the NCLEX?',
          'I require accommodations for writing exams, how can I receive accommodations for the NCLEX exam in Ontario?',
          'I am a nursing student. I will add my study notes. Summarize my lecture notes and highlight the most important concepts.',
          'I will add my nursing study notes. Write me a 20-question MCQ exam based on these lecture notes, with the answers provided separately.',
          'How do I write a personal reflection for nursing school?',
          'What is a pneumonic I can use to remember the signs/symptoms of sepsis?',
        ],
        administrativeSupport: [
          'What can an RN delegate to an LPN/RPN?',
          'I am a nursing student. How do I give a patient handover report?',
          'I will give you my placement and work experience. Write me a resume tailored to a new grad nursing role in the cardiology unit of a hospital.',
        ],
        clinicalSupport: [
          'Explain the best way to interpret a 12-lead ECG.',
          'What are the different routes of medication administration?',
          'How do you insert a nasogastric tube?',
          'How quickly should a bolus be administered during an emergency?',
          'What are the different types of oxygen delivery systems and when are they used?',
          'What does ionized calcium indicate on bloodwork, and why is it important?',
          'What is the difference between hemodialysis and peritoneal dialysis?',
        ],
        patientCareSupport: [
          'What is the Stages of Change model, and what happens in each stage?',
          'Simulate a conversation where you act as the parent of a child newly diagnosed with asthma, and I will act as the nurse where I will provide responses based on your concerns. Please provide feedback on my responses at the end.',
          'Explain how to best administer PINCH medications.',
          'Describe best practices while caring for a patient experiencing hypoglycemia.',
          'I am a nursing student. Create a template I can use to organize patient information during my clinical placement.',
          'Create drug card information for zofran.',
        ],
      },
      nurse: {
        administrativeSupport: [
          'I will provide you with my hospital unit policy. Summarize it and highlight the main ideas.',
          'I am a nurse working in the day surgery unit of a hospital. Write an email requesting 3 weeks leave of absence from my manager.',
          'Give me an example of a pre-op nursing assessment.',
          'Generate an incident report for my patient who had an unwitnessed fall.',
        ],
        clinicalSupport: [
          'What are the contraindications and precautions with ibuprofen?',
          'What is Reye’s Syndrome and its causes?',
          'What causes elevated AST levels?',
          'Can an RN draw blood from a hemodialysis line?',
          'What are the potential drug interactions with cefazolin?',
          'Which medications are contraindicated in people with diabetes?',
          'When can my patient continue breastfeeding after receiving IV sedation?',
          'Provide me with best practices on how to draw up propofol for IV administration in surgical practice.',
          'List all IV catheter gauges and indications for each.',
          'What are best practices for removing a central venous catheter at the bedside?',
          'I am a practicing nurse. Please suggest 3 community based interventions to promote healthy behaviours and prevent diabetes.',
          'What is the most up-to-date information on managing a patient with a diabetic foot ulcer?',
          'What are nursing considerations for patients with heart disease?',
          'What steps can be taken to prevent infection of a urinary catheter?',
          'What is the ACLS algorithm for a patient in pulseless-v tach?',
          'Explain the pathology of diabetic ketoacidosis.',
        ],
        symptomChecker: ['What medications or conditions could lead to tingling and numbness (paresthesia)?'],

        patientCommunication: [
          'Explain rheumatoid arthritis in simple language for a newly diagnosed patient.',
          'I am a practicing nurse. What strategies can I use to deal with a demanding patient?',
          'What are some tips for me, as a nurse, to help manage emotions in patients new to palliative care and for communicating with their family members?',
        ],
        personalDevelopment: [
          'I am a practicing nurse working 12 hour shifts. What are some tips for stress management and achieving work-life balance?',
          'I work 12 hour shifts as a nurse and like to pre-make my meals. Suggest some healthy meal ideas that I can pre-make for work.',
        ],
        professionalDevelopment: [
          'I am a registered nurse. What are some options to advance my career?',
          'I am a new nurse. I will give you my experience. Help me draft my resume to apply to a general position as a nurse in a hospital setting.',
          'List examples of non-clinical nursing jobs.',
        ],
        patientScenarios: [
          'Act as a patient in a simulated conversation to help me practice de-escalation techniques. I will respond as the nurse. Please provide feedback at the end.',
          'Simulate a conversation where I will act as the nurse, and you are a patient who presents in DKA to the emergency department. Please provide feedback at the end.',
        ],
        patientSupport: [
          'I have an adult patient living downtown Toronto. Where can I send him for diabetic education?',
          'What community supports are there in Lansing, Michigan for family members of patients with dementia?',
          'When a patient with dementia forgets something or someone, how do I handle it, as a practicing nurse?',
          'What are some tips to administer oral medications to a patient who usually refuses?',
        ],
      },
    },
  },

  apply: {
    titelA: "<span>Find the right job tailored to <span className='bold'>your</span> needs.</span>",
    complete: 'Complete',
    next: 'Next',
    submitButton: 'Submit',
    prev: 'Previous',
    certificationPlaceHolder: 'Certification Name',
    answerRequired: 'You must answer to proceed',
    columns: [
      {
        title: 'Easy and Fast',
        // title: 'Tailored Results',
        subTitle: 'Optimize your time',
        details:
          '<div><span>Step 1</span>: Answer a few questions to build your profile. No more hours spent scrolling through job postings or customizing cover letters.</div>',
      },

      {
        title: 'Tailored Results',
        subTitle: '<span>Find the right job for <span className="underline">you</span></span>',
        details:
          '<div><span>Step 2:</span> Sit back as smart’n AI identifies roles specifically aligned to <span className="underline">you</span>. We match not just your skills, but what you value and prioritize in a job.</div>',
      },

      {
        title: 'Better Outcomes',
        subTitle: 'We filter through the noise',
        details:
          '<div><span>Step 3:</span> We notify you of select jobs that our AI matches to your unique profile.  No flooding of your inbox with irrelevant job suggestions.</div>',
      },
    ],
    buttonPreText:
      "<span>Let <span className='smartn'>smart<span className='smartn-apostrophe'>’</span>n Apply</span> AI do the work for you",
    buttonText: 'Let’s get started',

    bannerTitle: "<span>Your <span className='purple'>Apply</span> Profile</span>",
    experience: {
      title:
        'Help us find the right job for you! Please answer the following questions to set up your profile. This will take less than 5 mins.',

      placeHolder: 'Type your answer here',
    },

    employmentType: {
      header: 'Please select a unique preference per type.',
    },

    license: {
      disclaimer: '*If you hold an NLC, please indicate your top 2 preferred states.',
      titleA: 'Select first location',
      titleB: 'Select second location',
    },

    uploadResume: {
      subTitle: 'Upload your resume to complete your profile.',
      buttonText: 'Upload Resume',
    },

    submit: {
      nextButton: 'Submit',
      titleA: 'Your resume has been uploaded.',
      titleB:
        "<div> Click <span className='purple'>Submit</span> to complete your profile,<br/> or <span className='purple'>Previous</span> to review your answers.",
    },
    confirmMissingCV: {
      title: 'Are you sure you don’t want to add your resume?',
      subTitle: ' Uploading a resume increases your chances of getting a job match.',
      disclaimer:
        "<div> Click <span className='purple'>Submit</span> to complete your profile without a resume,<br/> or <span className='purple'>Previous</span> to review your answers.",
    },

    notifications: {
      title: 'Your profile has been updated!',
      textNumber: 'Mobile number',
    },

    exit: {
      faq:
        "<span>Questions? Visit our <a target='_blank' href='https://www.smartn.ca/apply/faq'>FAQ</a> page for more information.</span>",
      notSupported: {
        title: '<span>Thank you for your interest in our <span className="purple">Apply</span> program.</span>',
        subTitle:
          'We do not have any current opportunities in your area. If a job opening matches your profile in the near future, we will email you to determine your interest.',
      },
      supported: {
        title: '<span>Thank you for your interest in our <span className="purple">Apply</span> program.</span>',
        subTitle:
          'We are working hard to find you a job aligned to your profile, and will notify you when we have a match.',
      },
      nurseGPT: {
        tile: 'NurseGPT',
        subTitle: 'Improve your efficiency',
        info: 'Access unlimited support from your personal AI nursing assistant',
      },

      study: {
        title: 'Study',
        subTitle: 'Optimize your learning',
        info: 'Refresh and assess your knowledge and clinical judgment',
      },
      infoList: [
        {
          title: 'NurseGPT',
          subtitle: 'Improve your efficiency',
          details: '<span>Access unlimited support from<br/>your personal AI nursing assistant<span>',
          detailsMobile: '<span>Access unlimited support from your personal AI nursing assistant<span>',
        },
        {
          title: 'Study',
          subtitle: 'Optimize your learning',
          details: '<span>Refresh and assess your<br/>knowledge and clinical judgment',
          detailsMobile: '<span>Refresh and assess your knowledge and clinical judgment',
        },
      ],
      buttonText: 'Go to Dashboard',
      resetText: 'Update',
      enhanceProfileTitle: 'Enhance your profile',
      enhancePorfileSubTitle: 'Optimize your job recommendations based on your exact preferences',
      enhanceProfileStartButton: 'Start',
      enhanceProfileContinue: 'Continue',
      enhanceProfileCompleted: 'Completed!',

      applyQuizTitle: 'Getting started Apply profile',
      applyQuizSubTitle: 'Education, employment and location',
    },

    patientCare: {
      subHeader: '"I prefer to care for..."',
    },

    nursingCare: {
      comfortable: 'Very comfortable',
      notComfortable: 'Not comfortable',
      mobileDisclaimer: '1 is not comfortable and 4 is very comfortable.',
    },

    submitEnhancement: {
      titleA: 'Almost done!',
      titleB:
        "<div> Click <span className='purple'>Submit</span> to enhance your profile,<br/> or <span className='purple'>Previous</span> to edit your answers.",
    },
    enhanceModal: {
      title: '<div>Answer a few more questions to <span>enhance your profile</span></div>',
      titleIncomplete: '<div>Complete your <span>enhanced profile</span></div>',
      subTitle: 'Increase your chances of getting a job that aligns with your values!',
      buttonText: 'Enhance my profile',
    },
  },

  dynamicField: {
    add: 'Add More',
    remove: 'Remove',
  },

  location: {
    country: 'Select country:',
    province: 'Select province/state:',
    city: 'Select city:',
    statePlaceHolder: 'Please select a country first',
    cityPlaceHolder: 'Please select a state/province first',
  },
};
