import { differenceInCalendarDays } from 'date-fns';
import { POLLING_ATTEMPTS, POLLING_DELAY, SINGLE_QUESTION_CASE_STUDIES } from './constants';
import {
  EQuestionTypes,
  IAttempt,
  IQuestion,
  IQuestionAnswersState,
  EProficiencyLevels,
  ILooseObject,
  ECaseStudyQuestionTypes,
  ICaseStudy,
  EReadinessPassingChances,
  EUserRole,
  ENurseGPTRole,
} from './types';
import axiosRequest, { IAxiosRequest } from './axios';
import { call, delay } from 'redux-saga/effects';
import { inRange, isEqual } from 'lodash';
import { colors } from '../themes';
import QuestionResponseTransformer from './classes/DataTransformers/QuestionResponseTransformer';
import { en } from '../i18n';

const humanize = (str: string) =>
  str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, '/')
    .replace(/([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, '$& ')
    .replace(/^[a-z]/, (word: string) => word.toUpperCase());

const isCorrectAnswer = (answer: string | string[], questionType: EQuestionTypes, answers?: string[]) => {
  // TODO: How will this be validated?
  if (questionType === EQuestionTypes.SHORT_ANSWER) return true;
  if (typeof answer === 'string') return answers?.includes(answer);
  return answer.every(answer => answers?.includes(answer)) && answer.length === answers?.length;
};

const countQuestionsTransformed = (transformedQuestions: { [key: string]: IQuestion }) => {
  return Object.values(transformedQuestions).reduce((acc, question) => {
    if (question.questionType === EQuestionTypes.NGN_CASE_STUDY)
      acc += ((question as unknown) as ICaseStudy).questions.length;
    else acc++;
    return acc;
  }, 0);
};

const countAnsweredQuestions = (questionAnswers: IQuestionAnswersState) => {
  return Object.values(questionAnswers).reduce((acc, question) => {
    if (question.subAnswers) {
      Object.values(question.subAnswers).forEach(sub => {
        if (sub.isDone) acc++;
      });
    } else {
      if (question.isDone) acc++;
    }
    return acc;
  }, 0);
};

const getCurrentProgress = (
  transformedQuestions: {
    [key: string]: IQuestion;
  },
  questionAnswers: IQuestionAnswersState,
  attempt?: IAttempt,
) => {
  if (!attempt) return { percentage: 0, upperBound: 0, lowerBound: 0 };
  const totalNumberOfQuestions = countQuestionsTransformed(transformedQuestions);
  const answeredQuestions = countAnsweredQuestions(questionAnswers);
  const percentage = Math.round((answeredQuestions / totalNumberOfQuestions) * 100);
  return { percentage, upperBound: totalNumberOfQuestions, lowerBound: answeredQuestions };
};

const findLastIndex = (arr: any[], callBack: (data: any) => Boolean) => {
  let lastIndex = -1;
  for (let i = 0; i < arr.length; i += 1) {
    if (callBack(arr[i])) {
      lastIndex = i;
    }
  }
  return lastIndex;
};

interface IPollRequestOptions {
  stopCallBack?: (data: any) => Boolean;
  pollingAttempts?: number;
  onlyContinueOnHTTPStatus?: number;
}
const pollRequest = function* pollRequest<T>(payload: IAxiosRequest, options: IPollRequestOptions = {}) {
  const { stopCallBack, onlyContinueOnHTTPStatus } = options;
  const pollingAttempts = options.pollingAttempts || POLLING_ATTEMPTS;
  for (let i = 0; i <= pollingAttempts; i++) {
    const delayInterval = i ** 2 * POLLING_DELAY;
    try {
      console.log(`attempting request ${i} to ${payload.url} `);
      const result: T = yield call(axiosRequest, payload);
      if (stopCallBack) {
        if (stopCallBack(result)) return result;
      } else {
        return result;
      }
      if (i < pollingAttempts) yield delay(delayInterval);
    } catch (error: any) {
      if (onlyContinueOnHTTPStatus) {
        const status = error?.response?.status;
        if (status && status !== onlyContinueOnHTTPStatus) throw error;
      }

      if (i < pollingAttempts) yield delay(delayInterval);
    }
  }
  return null;
};

const requestShowAll = function* requestShowAll(payload: ILooseObject) {
  let allData: any = [];
  let page = 1;
  const { url, pageSize, earliest } = payload;
  while (true) {
    const { data } = yield call(axiosRequest, {
      url: `${url}&page=${page}&pageSize=${pageSize}${earliest ? `&earliest=${earliest}` : ''}`,
    });
    allData = [...allData, ...data];
    if (data.length === 0) return allData;
    page++;
  }
};

const isEmptyValue = (value: any) => {
  if (value === null || value === undefined) return true;
  if (Array.isArray(value) || typeof value === 'string') return value.length === 0;
  return true;
};

const shouldForwardProps = (prop: PropertyKey, allowedProps: string[]) => !allowedProps.includes(prop as string);

const mapPercentageToTag = (percentage: number) => {
  if (isNaN(percentage)) percentage = 0;
  if (inRange(percentage, 0, 15))
    return {
      text: EProficiencyLevels.JUST_STARTED,
      textColor: colors.orange40,
      backgroundColor: colors.orange20,
    };
  if (inRange(percentage, 15, 40))
    return {
      text: EProficiencyLevels.SOME_UNDERSTANDING,
      textColor: colors.orange40,
      backgroundColor: colors.orange20,
    };
  if (inRange(percentage, 40, 60))
    return {
      text: EProficiencyLevels.GOOD_UNDERSTANDING,
      textColor: colors.darkPurple,
      backgroundColor: colors.lightPurple,
    };
  if (inRange(percentage, 60, 75))
    return {
      text: EProficiencyLevels.SOME_PROFICIENCY,
      textColor: colors.darkPurple,
      backgroundColor: colors.lightPurple,
    };
  if (inRange(percentage, 75, 90))
    return {
      text: EProficiencyLevels.PROFICIENT,
      textColor: colors.green20,
      backgroundColor: colors.green5,
    };
  return {
    text: EProficiencyLevels.EXPERT,
    textColor: colors.green20,
    backgroundColor: colors.green5,
  };
};

const mapPercentageToPassingChanceTag = (percentage: number, totalQuestionsAnswered: number, threshhold: number) => {
  const unavailable = {
    text: EReadinessPassingChances.UNAVAILABLE,
    textColor: colors.grey650,
    backgroundColor: colors.grey600,
  };

  if (totalQuestionsAnswered < threshhold) {
    return unavailable;
  }
  switch (true) {
    case inRange(percentage, 0, 0.5):
      return {
        text: EReadinessPassingChances.LOW,
        textColor: colors.smartNApostrophe,
        backgroundColor: colors.pink,
      };

    case inRange(percentage, 0.5, 0.85):
      return {
        text: EReadinessPassingChances.MEDIUM,
        textColor: colors.highlightBlue,
        backgroundColor: colors.blue50,
      };

    case inRange(percentage, 0.85, 1):
      return {
        text: EReadinessPassingChances.HIGH,
        textColor: colors.green20,
        backgroundColor: colors.green5,
      };
  }

  return unavailable;
};

const countQuestions = (attempt: IAttempt) => {
  return attempt.questions.reduce((acc, question) => {
    if (question.subAnswers) acc += getCaseStudyLength(question.questionId);
    else acc++;
    return acc;
  }, 0);
};

const calculateTotalMark = (attempt: IAttempt) => {
  return attempt.questions.reduce((acc, question) => {
    if (question.subAnswers) {
      Object.values(question.subAnswers).forEach(sub => {
        acc += sub.parsedMark?.mark || 0;
      });
    } else {
      acc += question.parsedMark?.mark || 0;
    }
    return acc;
  }, 0);
};

const calculateTotalMaximumMark = (attempt: IAttempt) => {
  return attempt.questions.reduce((acc, question) => {
    if (question.subAnswers) {
      Object.values(question.subAnswers).forEach(sub => {
        acc += sub.parsedMark?.maximumMark || 0;
      });
    } else {
      acc += question.parsedMark?.maximumMark || 0;
    }
    return acc;
  }, 0);
};

const calculateProficiency = (attempts: IAttempt[]) => {
  let totalMark = 0;
  let totalMaximumMark = 0;
  attempts.forEach(attempt => {
    if (!attempt.isDone || attempt.isOnBoarding) return;
    totalMark += calculateTotalMark(attempt);
    totalMaximumMark += calculateTotalMaximumMark(attempt);
  });
  const percentage = (totalMark / totalMaximumMark) * 100;
  return mapPercentageToTag(percentage);
};

const getCaseStudyLength = (questionId: string) => {
  if (SINGLE_QUESTION_CASE_STUDIES.includes(questionId)) return 1;
  return 6;
};

const isAttemptDone = (attempt: IAttempt, disregard = false, checkResponseTime = false) => {
  const questionResponseTransformer = new QuestionResponseTransformer();
  for (const question of attempt.questions) {
    // Resume for now
    const disRegardedIds = ['9deeea16-c9f5-11ed-afa1-0242ac120002', '04b838a6-5497-11ef-b864-0242ac120002'];
    const isDisregarded = disregard && disRegardedIds.includes(question.questionId);
    if (checkResponseTime && question.ctime) continue;
    if (!question.response && !isDisregarded) return false;
    if (!question.response || isDisregarded) continue;
    const transformedResponse = questionResponseTransformer.transform(question.response);
    if (
      transformedResponse[0] &&
      transformedResponse[0].id &&
      transformedResponse.length < getCaseStudyLength(question.questionId)
    )
      return false;
  }
  return true;
};

const centsToDollars = (cents: number) => (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

const countQuestionsAnswered = (attempts: IAttempt[]) => {
  return attempts?.reduce((acc, curr) => {
    curr.questions.forEach(question => {
      if (question.subAnswers) {
        Object.values(question.subAnswers).forEach(subAnswer => {
          if (subAnswer.isDone) acc++;
        });
      } else if (question.response) acc++;
    });
    return acc;
  }, 0);
};

interface IFormatOtions {
  showHours?: boolean;
  useTextFormat?: boolean;
  applyPadding?: boolean;
}
const humanReadableDuration = (msDuration: number | null, formatOptions?: IFormatOtions): string => {
  if (!msDuration) return '00:00';
  const { showHours = true, useTextFormat = false, applyPadding = true } = formatOptions || {};
  const h = Math.floor(msDuration / 1000 / 60 / 60);
  const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
  const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);
  const padding = (time: number) => {
    if (!applyPadding || time >= 10) return time;
    return `0${time}`;
  };

  const hours = padding(h);
  const minutes = padding(m);
  const seconds = padding(s);
  if (useTextFormat) {
    const minutesSeconds = `${minutes}m ${seconds}s`;
    if (!showHours) return minutesSeconds;
    return `${hours}h ${minutesSeconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;

  //return `${hours}h ${minutes}m ${seconds}s`;
};

const calculatePassingChancePercentage = (totalGrade: number, topicsResults: ILooseObject) => {
  const TOTAL_GRADE_WEIGHT = 0.8;
  const TOPICS_WEIGHT = 0.2;
  const topics = Object.keys(topicsResults);
  const topicsPercentage =
    topics.reduce((accum, currentTopic) => accum + topicsResults[currentTopic].percentage, 0) / topics.length;
  return TOTAL_GRADE_WEIGHT * totalGrade + TOPICS_WEIGHT * topicsPercentage;
};

const humanizeNumber = (number: number) => {
  if (number < 10) return `0${number}`;
  return number;
};

const getMonthAgo = () => {
  const todayDate = new Date();
  const todayDay = todayDate.getDate();
  todayDate.setDate(todayDay - 30);
  return todayDate.toISOString();
};

const isInternalUser = (email?: string) => {
  // return email?.endsWith('@yourika.ai');
  return true;
};

const getDateInterval = (date: number) => {
  const differenceInDays = differenceInCalendarDays(Date.now(), new Date(date));
  if (differenceInDays === 0) return 'Today';
  if (differenceInDays <= 7) return 'Previous 7 Days';
  if (differenceInDays <= 30) return 'Previous 30 Days';
  if (differenceInDays <= 365) return 'Previous Year';
  return 'Earlier';
};

const mapOnboardingRoleToNurseGPTRole = (role: EUserRole | JSX.Element) => {
  switch (role) {
    case EUserRole.UNDERGRAD:
    case EUserRole.GRAD:
    case EUserRole.JOB_SEEKER:
    case EUserRole.INSTRUCTOR:
    case EUserRole.USER:
      return ENurseGPTRole.NURSING_STUDENT;
    case EUserRole.NURSE:
      return ENurseGPTRole.PRACTICING_NURSE;
    case EUserRole.TUTOR:
      return ENurseGPTRole.NCLEX_TAKER;
    default:
      return '';
  }
};

const isJSONObject = (value: string) => {
  try {
    const parsedValue = JSON.parse(value);
    return typeof parsedValue === 'object';
  } catch (error) {
    return false;
  }
};

const uppercaseWords = (str: string) => str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

export {
  humanize,
  shouldForwardProps,
  getCurrentProgress,
  findLastIndex,
  pollRequest,
  isEmptyValue,
  calculateProficiency,
  isAttemptDone,
  requestShowAll,
  centsToDollars,
  countQuestions,
  countQuestionsAnswered,
  mapPercentageToPassingChanceTag,
  humanReadableDuration,
  calculatePassingChancePercentage,
  humanizeNumber,
  getCaseStudyLength,
  calculateTotalMark,
  calculateTotalMaximumMark,
  isCorrectAnswer,
  getMonthAgo,
  isInternalUser,
  getDateInterval,
  mapOnboardingRoleToNurseGPTRole,
  isJSONObject,
  uppercaseWords,
  getRandomInt,
};
